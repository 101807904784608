/** @jsxImportSource @emotion/react */

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import "./styles.css";
import { HIGHLIGHT_BUFFER, REQUIREMENT_CLASSNAMES } from "./constants";
import { Page } from "react-pdf";
import { ScreenSpinner } from "utils/icons";
import { GroupedBlock, StepValue } from "../types";
import { Copy, Split } from "lucide-react";
import { MouseEvent, memo, useCallback, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useMemo } from "react";
import tw from "twin.macro";
import { useSelection } from "./SelectionContext";
import { Requirement } from "types/Requirement";
import { useRequirementDragOverOperations, useUnmergeRequirement } from "./hooks";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { Section } from "components/copilot/CopilotSchemaTypes";
import { TailwindColors } from "const-values/Colors";
import copyText from "utils/copyText";
import { useNotification } from "context/notificationContext";
import { setRequirementsTableScrollToState } from "store/reducers/extract/CurrentExtractionReducer";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { handleShiftSelect, highlightPattern } from "./utils";
import { motion } from "framer-motion";
import { pluralizeWord } from "utils/string";

type Props = {
  page: number;
  scale: number;
  pageBlocks: GroupedBlock[];
  isReadOnly: boolean;
  dimensions: { width: number; height: number };
  onBlockSelected: (reqId: string) => void;
  sectionsMap: Record<string, ToImmutable<Section>>;
  keysOfSectionMap: string[];
  groupedFilteredRequirements: Record<string, Pick<Requirement, "id">>;
  visiblePage: number;
};

const PageWrapper = ({
  keysOfSectionMap,
  onBlockSelected,
  page,
  dimensions = { height: 0, width: 0 },
  scale,
  pageBlocks,
  isReadOnly,
  sectionsMap,
  groupedFilteredRequirements,
  visiblePage,
}: Props) => {
  const [rendered, setRendered] = useState(false);
  const searchQuery = useAppSelector((store) => store.currentExtractionState.searchQuery);
  const textRenderer = useCallback((textItem: any) => highlightPattern(textItem.str, searchQuery), [searchQuery]);

  const isVisible = visiblePage - 3 <= page && page <= visiblePage + 3;
  const blockElements = useMemo(
    () =>
      pageBlocks?.map((block) => (
        <PageBlock
          sectionsMap={sectionsMap}
          isActiveInFilter={!!groupedFilteredRequirements[block.requirement.requirement.id]}
          pageBlocks={pageBlocks}
          dimensions={dimensions}
          onBlockSelected={onBlockSelected}
          isReadOnly={isReadOnly}
          block={block}
          keysOfSectionMap={keysOfSectionMap}
          key={block.id}
          isVisible={isVisible}
        />
      )),
    [
      dimensions,
      groupedFilteredRequirements,
      isReadOnly,
      isVisible,
      keysOfSectionMap,
      onBlockSelected,
      pageBlocks,
      sectionsMap,
    ],
  );

  return (
    <div className="relative" style={{ height: dimensions.height }}>
      {isVisible ? (
        <Page
          onRenderTextLayerSuccess={() =>
            setTimeout(() => {
              setRendered(true);
            }, 100)
          }
          className="mx-auto w-fit"
          customTextRenderer={textRenderer}
          pageNumber={page}
          renderAnnotationLayer={false}
          scale={scale}
          loading={
            <div className="absolute inset-0 flex items-center justify-center" style={{ height: dimensions.height }}>
              <ScreenSpinner />
            </div>
          }
        >
          {rendered && blockElements}
        </Page>
      ) : (
        blockElements
      )}
    </div>
  );
};

interface PageBlockProps extends Omit<Props, "page" | "groupedFilteredRequirements" | "visiblePage" | "scale"> {
  block: GroupedBlock;
  isActiveInFilter: boolean;
  isVisible: boolean;
}

const PageBlock = memo(
  ({ block, keysOfSectionMap, dimensions, sectionsMap, isReadOnly, isActiveInFilter, isVisible }: PageBlockProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();
    const { selectedBlocks, setSelectedBlocks, isDragging: isDragSelecting } = useSelection();
    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const groupedBlocks = useAppSelector((store) => store.currentExtractionState.groupedBlocks);
    const orderKeys = useAppSelector((store) => store.currentExtractionState.orderKeys);
    const { handleUnmergeRequirement, isLoading: isUnmerging } = useUnmergeRequirement();
    const isSelected = useMemo(
      () => selectedBlocks?.some((selectedBlock) => selectedBlock.id === block.id),
      [block.id, selectedBlocks],
    );
    const { requirement, bounds } = block;
    const existsInTemplate = keysOfSectionMap.includes(requirement.proposal_reference.section_id || "");
    const { setToast } = useNotification();
    const { onDrag, onDragEnd, onDragStart, dragPosition, isDragging } = useRequirementDragOverOperations(block);

    const isRequirementsStep = extraction?.step === StepValue.Requirements;
    const isAssigned = existsInTemplate && !!requirement.proposal_reference.section_id;
    const isSkippedRequirement = !!requirement?.requirement?.skipped;
    const isSelectedRequirement = !requirement?.requirement?.skipped;
    const isSelectedAndHighlighted = !isReadOnly && !requirement?.requirement?.skipped;

    const requirementStateToClassName = isSkippedRequirement
      ? REQUIREMENT_CLASSNAMES.extracted
      : isAssigned
        ? REQUIREMENT_CLASSNAMES.assigned
        : isSelectedRequirement
          ? REQUIREMENT_CLASSNAMES.selected
          : "";

    const mouseLeaveHandler = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        const allSimilarBlocks = document.querySelectorAll(`[data-element='${requirement.requirement.element_id}']`);

        const className = isAssigned
          ? ""
          : isSkippedRequirement
            ? "hovered-extracted-requirement"
            : isSelectedAndHighlighted
              ? "hovered-selected-requirement"
              : "";

        allSimilarBlocks?.forEach((node) => {
          className && node.classList.remove(className);
        });

        e.stopPropagation();
      },
      [isAssigned, isSkippedRequirement, isSelectedAndHighlighted, requirement.requirement.element_id],
    );

    if (!requirement || !bounds) return null;
    const { top_left: topLeft, top_right: topRight, bottom_right: bottomRight } = bounds;

    const top = topRight.Y * dimensions.height - HIGHLIGHT_BUFFER;
    const left = topLeft.X * dimensions.width - HIGHLIGHT_BUFFER;
    const bottom = bottomRight.Y * dimensions.height + HIGHLIGHT_BUFFER;
    const right = topRight.X * dimensions.width + HIGHLIGHT_BUFFER;
    const width = right - left;
    const height = bottom - top;

    const handleRequirementCopy = (event: MouseEvent<HTMLButtonElement>, requirementContent: string) => {
      event.stopPropagation();
      copyText(requirementContent);
      setToast.success({ msg: "Copied to clipboard" });
    };

    if (!isVisible)
      return (
        <div
          data-element={requirement.requirement.element_id}
          className={`${requirementStateToClassName} !z-[2] outline-0 group absolute border border-transparent bg-transparent ds-selectable`}
          style={{
            top,
            left,
            width,
            height,
          }}
        />
      );

    const maxDragCount = 1;
    const extraDragCount = (selectedBlocks?.length || 0) - maxDragCount;

    return (
      <>
        {!isReadOnly && isDragging && !!dragPosition?.x && !!dragPosition?.y && (
          <motion.div
            transition={{ type: "tween", duration: 0.1 }}
            layoutId={block.requirement.requirement.id}
            className="flex flex-col gap-1 fixed z-[16] pointer-events-none"
            style={{ top: dragPosition?.y, left: dragPosition?.x - 120 }}
          >
            <div className="z-[6] text-white max-h-24 markdown-body line-clamp-4 bg-gray-darkest shadow-sharp rounded-md py-1 px-2 text-sm w-60">
              <Markdown remarkPlugins={[remarkGfm]} className="whitespace-pre-line w-full">
                {block.requirement.requirement?.content}
              </Markdown>
            </div>

            {extraDragCount > 0 && (
              <div className="w-fit z-[6] bg-gray-darkest shadow-sharp text-white rounded-md py-1 px-2 text-sm">
                +{extraDragCount} {pluralizeWord(extraDragCount, "requirement")}
              </div>
            )}
          </motion.div>
        )}
        <div
          draggable={!isReadOnly && isSelected}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDrag={onDrag}
          ref={ref}
          data-element={requirement.requirement.element_id}
          className={`${requirementStateToClassName} !z-[2] outline-0 group absolute border hover:!z-[3] border-gray-400 bg-[rgba(0,0,0,0.1)] ds-selectable ${
            isSelected ? "highlighted-dragged-selected-requirement" : ""
          }`}
          style={{
            top,
            left,
            width,
            height,
          }}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            if (event.shiftKey && selectedBlocks?.length) {
              return handleShiftSelect({
                selectedBlocks,
                orderKeys,
                filteredBlocks: groupedBlocks.allFilteredBlocks,
                requirementId: requirement.requirement.id,
                setSelectedBlocks,
              });
            }

            if (extraction?.id && !isReadOnly && isRequirementsStep) {
              setSelectedBlocks?.((prev) => {
                if (prev.some((selectedBlock) => selectedBlock.id === block.id)) {
                  return prev.filter((selectedBlock) => selectedBlock.id !== block.id);
                }
                return [...prev, block];
              });
            }
          }}
          css={[
            !isReadOnly && extraction?.step === StepValue.Requirements && tw`cursor-pointer`,
            isSkippedRequirement && tw`outline-gray-500`, // Extracted Requirement (Grey)
            isSelectedRequirement && tw`border-[#2a46ab] outline-[#2a46ab] bg-[rgba(42, 70, 171, .2)]`, // Selected Requirement (Blue)
            isAssigned && tw`bg-[rgba(14, 120, 8, .2)] outline-[rgb(14, 120, 8)] border-[rgb(14, 120, 8)]`, // Assigned Requirement (Green)
          ]}
          onMouseMove={
            !isReadOnly
              ? () => {
                  if (isDragSelecting) return;
                  const allSimilarBlocks = document.querySelectorAll(
                    `[data-element='${requirement.requirement.element_id}']`,
                  );

                  const className = isAssigned
                    ? ""
                    : isSkippedRequirement
                      ? "hovered-extracted-requirement"
                      : isSelectedAndHighlighted
                        ? "hovered-selected-requirement"
                        : "";
                  allSimilarBlocks?.forEach((node) => {
                    className && node.classList.add(className);
                  });
                }
              : undefined
          }
          onMouseLeave={!isReadOnly ? mouseLeaveHandler : undefined}
        >
          {!!requirement.proposal_reference.section_id && existsInTemplate && (
            <div
              className="cursor-pointer opacity-0 text-xxs select-none flex items-center gap-1.5 absolute p-0.5 shadow-sharp-thin rounded transition-opacity bg-gray-darkest text-gray-200 disable-drag-select group-hover:opacity-100 hover:bg-zinc-700"
              style={{
                top: 4,
                left: 4,
              }}
            >
              <div
                className="max-w-[200px] line-clamp-4 break-words px-1"
                onClick={(e) => {
                  e.stopPropagation();
                  const elementId = `requirement-row-${requirement.requirement.id}`;
                  const element = document.getElementById(elementId);
                  const allRequirementRows = document.querySelectorAll("[id^='requirement-row-']");
                  allRequirementRows.forEach((row) => {
                    row.classList.remove(TailwindColors.BgBlue100Important);
                  });

                  if (element) {
                    element.classList.add(TailwindColors.BgBlue100Important);

                    setTimeout(() => {
                      element.classList.remove(TailwindColors.BgBlue100Important);
                    }, 10000);
                  }

                  if (isAssigned) {
                    dispatch(
                      setRequirementsTableScrollToState({
                        requirementId: requirement.requirement.id,
                        sectionId: requirement.proposal_reference.section_id,
                      }),
                    );
                    return;
                  }
                }}
              >
                {sectionsMap[requirement.proposal_reference.section_id]?.title || "No section title"}
              </div>
            </div>
          )}
          <div
            className="flex flex-col items-start gap-1 absolute pl-1.5 -top-0.5 left-full opacity-0 group-hover:opacity-100"
            css={[isUnmerging && tw`opacity-100`, isActiveInFilter && tw`right-[calc(100%+26px)]`]}
          >
            {!isReadOnly && isRequirementsStep && block.isMergedRequirement && (
              <div onMouseMove={!isReadOnly ? mouseLeaveHandler : undefined}>
                <button
                  onClick={(e) => {
                    if (isUnmerging) return;
                    handleUnmergeRequirement(requirement.requirement.id);
                    e.stopPropagation();
                  }}
                  className="flex items-center gap-1.5 text-xs whitespace-nowrap py-1 px-2 bg-gray-darkest text-gray-200 shadow-sharp-thin rounded hover:bg-zinc-700 hover:text-white disabled:text-gray-300 disabled:cursor-not-allowed disabled:border-gray-500 disabled:bg-gray-400 disable-child-hover"
                  disabled={isUnmerging}
                >
                  {isUnmerging ? <SpinnerCircle className="h-3 w-3" /> : <Split size={12} />} Unmerge
                </button>
              </div>
            )}
            <button
              className="flex w-fit items-center gap-1.5 text-xs whitespace-nowrap py-1 px-2 bg-gray-darkest text-gray-200 shadow-sharp-thin rounded hover:bg-zinc-700 hover:text-white"
              onClick={(event) => {
                event.stopPropagation();
                handleRequirementCopy(event, requirement.requirement.content);
              }}
            >
              <Copy size={12} /> Copy
            </button>
          </div>
          {isActiveInFilter && (
            <div className="absolute pointer-events-none -top-0.5 -left-0.5 -right-0.5 -bottom-0.5 border-2 border-black">
              <div
                className="absolute pl-1.5 -top-0.5 left-full opacity-0 group-hover:opacity-100"
                css={[isUnmerging && tw`opacity-100`, isActiveInFilter && tw`right-[calc(100%+26px)]`]}
                onMouseMove={!isReadOnly ? mouseLeaveHandler : undefined}
              >
                <button
                  onClick={(e) => {
                    if (isUnmerging) return;
                    handleUnmergeRequirement(requirement.requirement.id);
                    e.stopPropagation();
                  }}
                  className="flex items-center gap-1.5 text-xs whitespace-nowrap py-1 px-2 bg-gray-darkest text-gray-200 shadow-sharp-thin rounded hover:bg-zinc-700 hover:text-white disabled:text-gray-300 disabled:cursor-not-allowed disabled:border-gray-500 disabled:bg-gray-400 disable-child-hover"
                  disabled={isUnmerging}
                >
                  {isUnmerging ? <SpinnerCircle className="h-3 w-3" /> : <Split size={12} />} Unmerge
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  },
);

export default memo(PageWrapper);
