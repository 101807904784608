import React, { ReactNode } from "react";

interface ToolbarButtonProps {
  onClick: () => void;
  children: ReactNode;
}

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center gap-2 h-7 px-2 border border-gray-500 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100"
    >
      {children}
    </button>
  );
};
