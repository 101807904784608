import { useEffect, useRef } from "react";
import VanillaSelectionArea from "@viselect/vanilla";
import { useProxyRef } from "hook/useProxyRef";
import "./styles.css";

interface DragSelectProps {
  onSelect: (id: string, isSelected: boolean) => void;
  selectedDocuments: string[];
  scrollContainerRef: React.RefObject<HTMLElement>;
}

export const useDragSelect = ({ onSelect, selectedDocuments, scrollContainerRef }: DragSelectProps): void => {
  const selectionRef = useRef<VanillaSelectionArea | null>(null);
  const documentsRef = useProxyRef(selectedDocuments);
  const isDraggingRef = useProxyRef(false);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const selection = new VanillaSelectionArea({
      container: scrollContainer, // Query selector or dom-node to set up container for the selection-area element.
      selectables: ".card", // Query selectors for elements which can be selected.
      boundaries: scrollContainer, // Query selectors for elements which will be used as boundaries for the selection.
      behaviour: {
        startThreshold: 10, // how many pixels the point should move before starting the selection
        scrolling: {
          speedDivider: 8,
          manualSpeed: 350,
          startScrollMargins: {
            y: 40, // Start scrolling when 40px from edge
          },
        },
      },
      features: {
        touch: false,
        range: false,
        singleTap: {
          allow: false,
        },
      },
    });

    selectionRef.current = selection;

    selection.on("beforestart", ({ event }) => {
      const target = event?.target;
      if (!target || !(target instanceof HTMLElement)) return false;

      // prevent selection to trigger when dragging elements with data-drag-ignore
      return !target.closest("[data-drag-ignore]");
    });

    selection.on("start", () => {
      if (isDraggingRef.current) return;

      // clear all selections
      documentsRef.current.forEach((id) => onSelect(id, false));

      selection.clearSelection();
    });

    selection.on(
      "move",
      ({
        store: {
          changed: { added, removed },
        },
      }) => {
        if (isDraggingRef.current) return;

        added.forEach((el) => {
          if (el instanceof HTMLElement) {
            const docId = el.dataset.docId;
            if (docId && !documentsRef.current.includes(docId)) {
              onSelect(docId, true);
            }
          }
        });

        removed.forEach((el) => {
          if (el instanceof HTMLElement) {
            const docId = el.dataset.docId;
            if (docId && documentsRef.current.includes(docId)) {
              onSelect(docId, false);
            }
          }
        });
      },
    );

    selection.on("stop", () => {
      isDraggingRef.current = false;
    });

    return () => {
      selection.destroy();
    };
  }, [onSelect, documentsRef]);
};
