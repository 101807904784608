export function move<T extends any>(array: T[], moveIndex: number, toIndex: number) {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
}

export function bulkMove<T>(array: T[], moveIndices: number[], toIndex: number): T[] {
  const itemsToMove = moveIndices.map((index) => array[index]);
  const remainingItems = array.filter((_, index) => !moveIndices.includes(index));

  // Calculate the insertion index relative to the remaining items
  const insertIndex = Math.min(
    toIndex,
    remainingItems.length, // Ensure it doesn't exceed array bounds
  );

  return [...remainingItems.slice(0, insertIndex), ...itemsToMove, ...remainingItems.slice(insertIndex)];
}

export function insert(array: any[], item: any, insertIndex: number) {
  return [...array.slice(0, insertIndex), ...[item].flat(), ...array.slice(insertIndex)];
}
