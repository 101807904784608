import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateInternalContractDetails, fetchInputDetails } from "api/api";
import Accordion from "./Accordion";
import flash from "../../Assets/accordianflash.svg";
import LinksSection from "./LinksSection";
import Names from "./components/Names";
import Source from "./Source";
import Tooltip from "components/atoms/tooltip/Tooltip";
import WinThemesContent from "components/organisms/win-themes-content";
import { RiInformationFill } from "@remixicon/react";
import { useFlags } from "hook/useFlags";
import type { Contract } from "types/Project";
import type { InternalContract } from "types/InternalContract";

type Props = {
  searchPage?: boolean;
  contractDetails?: Contract;
  internalContractId: string;
  internalContractDetails?: InternalContract;

  setForceRefresh: (force: boolean) => unknown;
};

const DESCRIPTION_FALLBACK = "No description provided.";

const ContractRightContent = ({
  searchPage,
  contractDetails,
  internalContractId,
  internalContractDetails,
  setForceRefresh,
}: Props) => {
  const [companyName, setCompanyName] = useState("");
  const flags = useFlags();

  useEffect(() => {
    if (!internalContractId) return;
    fetchInputDetails(internalContractId)
      .then((res) => {
        setCompanyName(res?.data.company_name);
      })
      .catch(() => {})
      .finally(() => {});
  }, [internalContractId]);

  const location = useLocation();

  const updateDescription = async (description: string) => {
    try {
      await updateInternalContractDetails(internalContractDetails?.internal_contract?.id, {
        description,
      });
    } catch {}
  };

  const description =
    (searchPage ? contractDetails?.original_description : internalContractDetails?.internal_contract?.description) ||
    DESCRIPTION_FALLBACK;

  return (
    <>
      {searchPage && (
        <div>
          <Accordion
            title={"AI Overview"}
            description={
              contractDetails?.summarized_description && contractDetails.summarized_description.length > 0
                ? contractDetails?.summarized_description
                : "No overview available."
            }
            selfMade={true}
            icon={flash}
            initialState={true}
          />
          <Accordion
            initialState
            title={<div className="flex items-center gap-2">{searchPage ? "Original Description" : "Description"}</div>}
            description={description}
            selfMade={true}
            paraStyle={{ whiteSpace: "pre-wrap" }}
            isEditable={location.pathname.includes("/contracts/details")}
            updateDescFunc={updateDescription}
          />
          <Source
            searchPage={true}
            contractDetails={contractDetails}
            internalContractId={internalContractId}
            internalContractDetails={internalContractDetails}
            setForceRefresh={setForceRefresh}
          />
          <LinksSection
            links={internalContractDetails?.links || contractDetails?.links || []}
            searchPage={searchPage}
            internalContractId={internalContractId}
            setForceRefresh={setForceRefresh}
          />
        </div>
      )}
      {!searchPage && (
        <div>
          <div className="border border-[#D8DDE2] p-4 shadow-sm rounded-lg mb-4 bg-gray-lightest">
            <h3 className="text-base font-medium">Inputs</h3>
          </div>
          <Names internalContractDetails={internalContractDetails} companyName={companyName} />
          <Accordion
            initialState
            title={
              <div className="flex">
                <span className="flex items-center gap-2">{searchPage ? "Original Description" : "Description"}</span>
                {!searchPage && (
                  <Tooltip content="Vultron will use this description as context when generating content.">
                    <div className="relative flex items-center justify-center mt-1 ml-2 h-[15px] w-[15px]">
                      <RiInformationFill size={14} className="text-action rounded-full z-[1]" />
                      <div
                        className="pulse-animation absolute rounded-full left-0.5 right-0.5 top-0.5 bottom-0.5"
                        style={{ "--pulse-bg": "rgba(42, 71, 171, 0.7)" }}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            }
            description={description}
            selfMade={true}
            paraStyle={{ whiteSpace: "pre-wrap" }}
            isEditable={location.pathname.includes("/contracts/details")}
            updateDescFunc={updateDescription}
          />
          {!flags.capture && (
            <div className="border border-[#D8DDE2] shadow-sm rounded-lg p-4 bg-white mb-4">
              <div className="flex">
                <h3 className="text-base font-medium">Win Themes</h3>
                <Tooltip content="Vultron will use the win themes to power its generated content.">
                  <div className="relative flex items-center justify-center mt-1 ml-2 h-[15px] w-[15px]">
                    <RiInformationFill size={14} className="text-action rounded-full z-[1]" />
                    <div
                      className="pulse-animation absolute rounded-full left-0.5 right-0.5 top-0.5 bottom-0.5"
                      style={{ "--pulse-bg": "rgba(42, 71, 171, 0.7)" }}
                    />
                  </div>
                </Tooltip>
              </div>
              <WinThemesContent />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ContractRightContent;
