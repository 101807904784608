/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import { useAppDispatch } from "store/storeTypes";
import { StepValue } from "../../types";
import { ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { setEditableTemplateRowState } from "store/reducers/extract/CurrentExtractionReducer";
import DraftRow, { SortableItem } from "./draft-row";
import useExtractionOperations from "hook/useExtractionOperations";
import { GripVertical, LucidePlus } from "lucide-react";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { useCurrentFormattedExtraction, useDrag } from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import ContextBankManager from "./context-bank-manager";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { isInstantDraftStarted } from "../../utils";
import tw from "twin.macro";
import { useFlags } from "hook/useFlags";
import LiveTemplateExtractionTemplate from "./live-template-extraction-template";
import { Outline } from "./outline-editor/Outline";
import RequirementListContainer from "./requirement-list-container";

const TemplateManager = () => {
  const dispatch = useAppDispatch();
  const { addNewVolume } = useExtractionOperations();
  const flags = useFlags();

  const { extraction, formattedDrafts } = useCurrentFormattedExtraction();
  const isReadOnly =
    extraction?.status === ExtractionStatus.Completed || isInstantDraftStarted(extraction?.instantDraftConfig?.status);

  const isImportStep = extraction?.step === StepValue.Review;

  const { sensors, handleDragEnd, handleDragStart, handleDragCancel, activeDragId, dragDrafts } = useDrag(
    formattedDrafts,
    extraction?.id,
  );

  const activeDraft = useMemo(() => dragDrafts.find((draft) => draft.id === activeDragId), [activeDragId, dragDrafts]);

  if (!extraction) return null;

  return (
    <div className="flex flex-1 flex-col relative h-full overflow-hidden">
      <div className="flex-1 flex relative h-full overflow-hidden">
        <div className="flex flex-col gap-4 flex-1 w-full">
          <DndContext
            sensors={sensors}
            collisionDetection={verticalSortableListCollisionDetection}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
          >
            <div className="h-full flex flex-row">
              <PanelGroup direction="horizontal">
                <Panel defaultSize={55} minSize={20} className="h-full flex flex-col">
                  <div
                    className="relative h-full"
                    css={[
                      !flags.textOutlineEditor && tw`overflow-y-auto`,
                      isReadOnly && !flags.textOutlineEditor && tw`pl-2`,
                    ]}
                    id={!flags.textOutlineEditor ? "generation-outline-editor-scroll-container" : undefined}
                  >
                    {flags.textOutlineEditor ? (
                      <Outline drafts={dragDrafts} extractionId={extraction.id} isReadOnly={isReadOnly} />
                    ) : (
                      !dragDrafts.length &&
                      !isReadOnly && (
                        <div className="p-2">
                          <button
                            onClick={() => {
                              if (extraction?.id) {
                                const createdDraft = addNewVolume(extraction.id);
                                setTimeout(
                                  () =>
                                    dispatch(
                                      setEditableTemplateRowState({
                                        localValue: createdDraft.title,
                                        id: createdDraft.id,
                                      }),
                                    ),
                                  80,
                                );
                              }
                            }}
                            className="flex items-center gap-1.5 border border-gray-400 text-sm px-2 py-1 bg-gray-100 w-full text-gray-600 rounded-md duration-100 hover:border-gray-600 hover:text-gray-800 hover:bg-gray-200"
                          >
                            <LucidePlus size={16} />
                            New Volume
                          </button>
                        </div>
                      )
                    )}
                    {!flags.textOutlineEditor && !!dragDrafts.length && (
                      <>
                        <div className="pb-4">
                          <SortableContext
                            id="EXTRACTION_TEMPLATE_DRAFTS"
                            items={dragDrafts || []}
                            strategy={verticalListSortingStrategy}
                          >
                            {dragDrafts?.map((draft) => <SortableItem key={draft.id} draft={draft} />)}
                            <DragOverlay style={{ transformOrigin: "0 0" }}>
                              {!!activeDragId && activeDraft && <DraftRow draft={activeDraft} isDragging />}
                            </DragOverlay>
                          </SortableContext>
                        </div>
                      </>
                    )}
                    {flags.liveTemplateGeneration && !isReadOnly && <LiveTemplateExtractionTemplate />}
                  </div>
                </Panel>
                <PanelResizeHandle className="z-[15] relative group flex justify-center">
                  <div className="z-[15] absolute top-14 py-0.5 -left-[6.5px] rounded bg-slate-800 hover:bg-gray-darkest">
                    <GripVertical size={14} className="text-white" />
                  </div>
                  <div className="w-px h-full bg-gray-200 delay-300 duration-150 group-hover:bg-slate-800 group-hover:scale-x-[2.5]" />
                </PanelResizeHandle>
                <Panel className="relative">
                  <RequirementListContainer dragDrafts={dragDrafts} isReadOnly={isReadOnly} />
                </Panel>
              </PanelGroup>
            </div>
          </DndContext>
        </div>
      </div>
      {!isImportStep && !!extraction.framework.context_bank?.length && <ContextBankManager />}
    </div>
  );
};

export default TemplateManager;
