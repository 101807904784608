import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import type { FeatureFlagSet } from "types/FeatureFlagSet";
import { STATIC_FEATURE_FLAG_MAP } from "const-values/StaticFeatureFlagMap";

function tryParseValue(value: string): any {
  const trimmed = value.trim();

  if (trimmed === "true") return true;
  if (trimmed === "false") return false;

  if (!isNaN(Number(trimmed)) && trimmed !== "") {
    return Number(trimmed);
  }

  try {
    const parsed = JSON.parse(trimmed);
    if (typeof parsed === "object" && parsed !== null) {
      return parsed;
    }
    return parsed;
  } catch {
    return trimmed;
  }
}

export function useParsedQueryParams(allowURLOverrides: boolean): Partial<FeatureFlagSet> {
  const { search } = useLocation();

  return useMemo(() => {
    if (!allowURLOverrides) {
      return {};
    }

    const params = new URLSearchParams(search);
    const parsedParams: Partial<FeatureFlagSet> = {};

    for (const [key, value] of Array.from(params)) {
      if (key in STATIC_FEATURE_FLAG_MAP) {
        parsedParams[key as keyof FeatureFlagSet] = tryParseValue(value);
      }
    }

    return parsedParams;
  }, [search, allowURLOverrides]);
}
