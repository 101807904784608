/** @jsxImportSource @emotion/react */

import { ComponentProps, useMemo, useRef } from "react";
import tw from "twin.macro";
import { pluralizeWord } from "utils/string";
import { Empty } from "components/molecules/empty";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { Volume } from "components/copilot/CopilotSchemaTypes";
import type { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { ViewportList } from "react-viewport-list";
import RequirementList from "./RequirementList";
import RequirementsListHeader from "./RequirementsListHeader";
import { useCurrentFormattedExtraction } from "../hooks";
import { useSelection } from "../../../document-display/SelectionContext";
import { StepValue } from "../../../types";
import { DndContext, DragOverlay, MeasuringStrategy, UniqueIdentifier } from "@dnd-kit/core";
import { useDrag } from "./hooks";
import RequirementItem from "./RequirementItem";
import RequirementSectionListContainer from "./RequirementSectionListContainer";
import { useAppSelector } from "store/storeTypes";

type Props = {
  dragDrafts: (Omit<ToImmutable<Volume>, "sections"> & { sections: FormattedSection[] })[];
  isReadOnly: boolean;
};

const RequirementListContainer = ({ dragDrafts, isReadOnly }: Props) => {
  const { selectedBlocks } = useSelection();
  const { extraction } = useCurrentFormattedExtraction();
  const isRequirementsStep = extraction?.step === StepValue.Requirements;
  const isImportStep = extraction?.step === StepValue.Review;
  const viewportRef = useRef<HTMLDivElement | null>(
    document.getElementById("template-manager-section-scroll") as HTMLDivElement | null,
  );
  const isDragActive = useAppSelector((store) => store.dragDropState.isDragActive);

  // const groupedComplianceMatrix: Record<string, ToImmutable<Extraction["compliance_matrix"]>> = useMemo(() => {
  //   if (!isRequirementsStep && !isImportStep) return {};
  //   return groupBy(
  //     (
  //       extraction?.compliance_matrix.filter(
  //         (row) => !row.requirement.soft_deleted && !!row.proposal_reference.section_id && !row.requirement.skipped,
  //       ) || []
  //     ).sort((a, b) => (a.requirement.section_order || 0) - (b.requirement.section_order || 0)),
  //     (row) => row.proposal_reference.section_id,
  //   );
  // }, [extraction?.compliance_matrix, isRequirementsStep, isImportStep]);
  const flattenedSections = useMemo(
    () =>
      dragDrafts.map((draft) => ({
        ...draft,
        sections: draft.sections.flatMap((section) => [section, ...(section.subsections || [])]),
      })),
    [dragDrafts],
  );
  const {
    collisionDetectionStrategy,
    sensors,
    handleDragCancel,
    handleDragEnd,
    handleDragStart,
    activeDragId,
    handleDragOver,
    groupedComplianceMatrix,
  } = useDrag({
    isImportStep,
    isRequirementsStep,
    complianceMatrix: extraction?.compliance_matrix,
    flattenedSections,
  });

  const extractionHasSections = useMemo(
    () => extraction?.framework?.volumes?.some((vol) => vol.sections?.length),
    [extraction?.framework?.volumes],
  );
  // const containerItems = useMemo(() => {
  //   return dragDrafts?.reduce<(FormattedVolume | FormattedSection | ToImmutable<ComplianceMatrixRow>)[]>(
  //     (acc, draft) => {
  //       let loadedSectionsAndRequirements: (FormattedSection | ToImmutable<ComplianceMatrixRow>)[] = [];
  //       const sections = draft.sections.flatMap((section) => [section, ...(section.subsections || [])]);
  //       sections.forEach((section) => {
  //         const sectionRequirements =
  //           groupedComplianceMatrix[section.id]?.map((row) => ({ id: row.requirement.id, ...row })) || [];
  //         const sectionAndRequirements = [section, ...sectionRequirements];
  //         loadedSectionsAndRequirements = sectionAndRequirements;
  //       });
  //       return [...acc, draft, ...loadedSectionsAndRequirements];
  //     },
  //     [],
  //   );
  // }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragOver={handleDragOver}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
    >
      {!!selectedBlocks?.length && extractionHasSections && (
        <>
          <div className="bg-gray-darkest text-white text-[13px] p-1 py-2 flex items-center justify-center">
            Hover below to add selected {pluralizeWord(selectedBlocks.length, "requirement")}
          </div>

          <div className="absolute inset-0 border-4 border-dashed border-gray-darkest pointer-events-none z-10" />
        </>
      )}
      <div
        ref={viewportRef}
        className="overflow-y-auto relative"
        id="template-manager-section-scroll"
        css={[selectedBlocks?.length || isDragActive ? tw`h-[calc(100%)] pb-20` : tw`h-full`, { zIndex: 5 }]}
      >
        {!flattenedSections.length && (
          <div className="px-8 h-full">
            <Empty
              heading="No Requirements Assigned"
              title="Begin by creating an outline and assigning requirements from the document"
            />
          </div>
        )}
        <ViewportList
          // overscan={100}
          // ref={listRef}
          viewportRef={viewportRef}
          items={flattenedSections || []}
        >
          {(draftItem, i) => {
            const { sections, id: volumeId, title: volumeTitle } = draftItem;

            return (
              <div key={volumeId} className="flex flex-col relative" id={`template-manager-volume-${volumeId}`}>
                <RequirementsListHeader
                  volumeId={volumeId}
                  extractionId={extraction?.id}
                  title={volumeTitle}
                  isReadOnly={isReadOnly}
                />
                <ViewportList
                  overscan={100}
                  // ref={listRef}
                  viewportRef={viewportRef}
                  items={sections || []}
                >
                  {
                    (sectionItem, i) => (
                      // {
                      <RequirementSectionListContainer
                        key={sectionItem.id}
                        groupedComplianceMatrix={groupedComplianceMatrix}
                        isReadOnly={isReadOnly}
                        volumeId={volumeId}
                        extractionId={extraction?.id || ""}
                        section={sectionItem}
                      />
                    )
                    // )

                    //   const { id: sectionId, title, parent_id } = sectionItem;
                    //   return (
                    //     <div
                    //       key={sectionId}
                    //       className="flex flex-col relative group"
                    //       id={`template-manager-section-${sectionId}`}
                    //     >
                    //       <RequirementsListHeader
                    //         sectionId={sectionId}
                    //         volumeId={volumeId}
                    //         extractionId={extraction?.id}
                    //         title={title}
                    //         parentId={parent_id}
                    //         isReadOnly={isReadOnly}
                    //       />
                    //       <RequirementList
                    //         requirements={groupedComplianceMatrix[sectionId] || []}
                    //         sectionId={sectionId}
                    //       />
                    //     </div>
                    //   );
                    // }
                  }
                </ViewportList>
              </div>
            );
            // if ("sections" in containerItem) {
            //   const {  id: volumeId, title: volumeTitle } = containerItem;
            //   return (
            //     <RequirementsListHeader
            //       volumeId={volumeId}
            //       extractionId={extraction?.id}
            //       title={volumeTitle}
            //       isReadOnly={isReadOnly}
            //     />
            //   );
            // } else if () {
            //   const { id: sectionId, title, parent_id } = containerItem
            //   return  <RequirementsListHeader
            //       sectionId={sectionId}
            //       volumeId={volumeId}
            //       extractionId={extraction?.id}
            //       title={title}
            //       parentId={parent_id}
            //       isReadOnly={isReadOnly}
            //     />
            // }
          }}
        </ViewportList>
        {/* {flattenedSections.map(({ sections, id: volumeId, title: volumeTitle }) => (
          <div key={volumeId} className="flex flex-col relative" id={`template-manager-volume-${volumeId}`}>
            <RequirementsListHeader
              volumeId={volumeId}
              extractionId={extraction?.id}
              title={volumeTitle}
              isReadOnly={isReadOnly}
            />
            {sections.map(({ id: sectionId, title, parent_id }) => (
              <div
                key={sectionId}
                className="flex flex-col relative group"
                id={`template-manager-section-${sectionId}`}
              >
                <RequirementsListHeader
                  sectionId={sectionId}
                  volumeId={volumeId}
                  extractionId={extraction?.id}
                  title={title}
                  parentId={parent_id}
                  isReadOnly={isReadOnly}
                />
                <RequirementList requirements={groupedComplianceMatrix[sectionId] || []} sectionId={sectionId} />
              </div>
            ))}
          </div>
        ))} */}
      </div>
      <DragOverlay style={{ transformOrigin: "0 0 " }}>
        {!!activeDragId && (
          <RenderDraggingOverlay requirements={extraction?.compliance_matrix || []} activeDragId={activeDragId} />
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default RequirementListContainer;

interface DragProps extends Pick<ComponentProps<typeof RequirementItem>, "sectionId" | "idx"> {
  activeDragId: UniqueIdentifier;
  requirements: ComponentProps<typeof RequirementList>["requirements"];
}

const RenderDraggingOverlay = ({ activeDragId, requirements }: DragProps) => {
  const activeRequirement = useMemo(
    () => requirements.find((row) => row.requirement.id === activeDragId),
    [activeDragId, requirements],
  );
  if (!activeRequirement) return null;
  return (
    <div className="markdown-body">
      <RequirementItem isReadOnly row={activeRequirement} isDragging />
    </div>
  );
};
