/** @jsxImportSource @emotion/react */

import { Sheet, Storage, Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import EditableContent from "components/molecules/editable-content";
import tw, { theme } from "twin.macro";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { PanelRight, PanelRightOpen, Plus } from "lucide-react";
import { RiAiGenerate } from "@remixicon/react";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { useCreateAndAssignRequirement } from "components/copilot/Framework/hooks";
import Tooltip from "components/atoms/tooltip";
import { Outlet, useLocation, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { ControlItemSlug, useRouteRequirement, useSseSectionProposal } from "./hooks";
import { useCallback, useEffect, useMemo } from "react";
import { useObserveSseController } from "hook/useObserveSseController";
import BottomBar from "./BottomBar";
import { PREVIEW_LAUNCH_DATE, WHITELIST_PROJECT_IDS, WHITELIST_WORKSPACE_IDS } from "const-values/Preview";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useStorage, useUpdateMyPresence } from "YJSProvider/createYJSContext";
import { isEqual } from "lodash";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { toggleActions, toggleComments } from "store/reducers/copilot/copilotDrawerReducer";
import useCreateAtlasRequirement from "hook/Requirements/useCreateRequirement";
import { EMPTY_SHEET } from "const-values/Sheets";

const DraftSection = () => {
  const { sectionId, volumeId } = useParams();
  const [workspace_id] = usePersistedStorage<string>("vultron_workspace_id");
  const actionsPanelOpen = useAppSelector((store) => store.copilotDrawer.open);
  const sidebarSize = useAppSelector((store) => store.sectionState.sidebarSize);
  const { open } = useAppSelector((root) => root.copilotDrawer);
  const { volume } = useOutletContext<{ volume?: Volume }>();
  const createAndAddToSection = useCreateAndAssignRequirement();
  const { createRequirement: createAtlasRequirement, isCreatingRequirement } = useCreateAtlasRequirement();
  const { updateSectionTitle, updateSectionManager } = useFrameworkOperations();
  const { abortConnection } = useSseSectionProposal();
  const updateMyPresence = useUpdateMyPresence();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const sheets: Sheet[] = useStorage((storage) => [...((storage.sheets as Storage["sheets"]) || [])]);

  const requirements = useStorage(
    (storage) =>
      (storage.compliance_matrix as Storage["compliance_matrix"]).filter(
        (row) => row.proposal_reference.section_id === sectionId && !row.requirement.skipped,
      ),
    isEqual,
  );
  const activeProject = useAppSelector((state) => state.project.activeProject);
  useRouteRequirement();
  const { pathname } = useLocation();
  const isProposal = useMemo(() => pathname?.split("/").includes(ControlItemSlug.proposal), [pathname]);

  useEffect(() => {
    // open comments if threadId is in URL (pasting link)
    const urlThreadId = searchParams.get("threadId");
    if (urlThreadId) {
      setTimeout(() => {
        dispatch(toggleComments(true));
      });
    }
  }, []);

  useEffect(() => {
    abortConnection();
    return () => {
      dispatch(setCheckedState({}));
    };
  }, [sectionId, dispatch, abortConnection]);

  const section = useStorage((storage) => {
    if (volume) return volume?.sections?.find((section) => section.id === sectionId);

    return (storage.framework as Storage["framework"]).volumes
      ?.find((volume) => volume.id === volumeId)
      ?.sections?.find((section) => section.id === sectionId);
  });

  useObserveSseController(abortConnection, () => {
    updateMyPresence({
      selectedId: null,
    });
  });

  const onCreateRequirement = useCallback(async () => {
    if (!section) return;

    try {
      const atlasRequirement = await createAtlasRequirement({ content: "" });
      const defaultSheetId = sheets[0]?.id;

      const createdRequirement = createComplianceMatrixRowRequirement({
        id: atlasRequirement?.id,
        ...(defaultSheetId && defaultSheetId !== EMPTY_SHEET.id && { extraction_id: defaultSheetId }),
      });

      createAndAddToSection(section.id, undefined, { requirement: createdRequirement });
      dispatch(setRequirementsState({ autoScroll: true }));
    } catch (error) {
      console.error("Failed to create requirement:", error);
    }
  }, [createAndAddToSection, createAtlasRequirement, dispatch, section, sheets]);

  const shouldShowPreview = useMemo(
    () =>
      WHITELIST_WORKSPACE_IDS.includes(workspace_id || "") ||
      WHITELIST_PROJECT_IDS.includes(activeProject?.internal_contract.id || "") ||
      new Date(activeProject?.internal_contract?.created_at || PREVIEW_LAUNCH_DATE).getTime() >=
        new Date(PREVIEW_LAUNCH_DATE).getTime(),
    [activeProject?.internal_contract?.created_at, activeProject?.internal_contract.id, workspace_id],
  );

  return (
    <div className="flex flex-col gap-1.5 h-full pt-3">
      <div className="pl-3.5 py-1 pr-5 flex items-start gap-4">
        <EditableContent
          submitOnEnter
          content={section?.title || ""}
          onContentUpdate={(val) => volumeId && section?.id && updateSectionTitle(volumeId, section.id, val)}
          css={[tw`font-medium flex-1`]}
          heightProps={tw`line-clamp-2 max-h-12 overflow-hidden`}
          textareaProps={{ forceResetProps: [sidebarSize, actionsPanelOpen], forceResetDelay: 150 }}
        />
        <div className="flex items-center gap-2">
          <button disabled={isProposal || isCreatingRequirement} onClick={onCreateRequirement}>
            <div
              className="bg-slate-200 text-slate-700 font-medium shadow-sharp-thin flex items-center rounded-md pr-3 pl-2 py-1.5 text-sm duration-100 hover:bg-slate-300 hover:text-slate-900"
              css={[isProposal && tw`group-disabled:bg-gray-200 group-disabled:text-slate-400 shadow-none`]}
            >
              <Plus size={16} className="mr-1" /> Requirement
            </div>
          </button>
          {!!section?.section_manager?.isIgnored && (
            <Tooltip content={"Show instant pink team draft"}>
              <button
                className="text-base relative bg-slate-200 text-slate-700 h-8 w-8 flex items-center rounded-md justify-center shadow-sharp-thin duration-100 hover:bg-slate-300 hover:text-slate-900"
                onClick={() => {
                  if (volume?.id && section?.id)
                    updateSectionManager(volume?.id, section?.id, {
                      isIgnored: false,
                    });
                }}
              >
                <div
                  className="rounded-full w-3 h-3 absolute -top-1.5 -right-1.5 pulse-animation bg-pink-300"
                  style={{ "--pulse-bg": theme`colors.pink.300` }}
                />
                <RiAiGenerate size={16} />
              </button>
            </Tooltip>
          )}
          <button
            className="text-base relative bg-slate-200 text-slate-700 h-8 w-8 flex items-center rounded-md justify-center shadow-sharp-thin duration-100 hover:bg-slate-300 hover:text-slate-900"
            onClick={() => dispatch(toggleActions(!open))}
          >
            {open ? <PanelRight size={16} /> : <PanelRightOpen size={16} />}
          </button>
        </div>
      </div>
      <div className="relative flex flex-col h-full">
        <Outlet context={{ section, volume, abortConnection, requirements }} />
        {!shouldShowPreview && <BottomBar section={section} abortConnection={abortConnection} />}
      </div>
    </div>
  );
};

export default DraftSection;
