/** @jsxImportSource @emotion/react */

import { ArrowDown, ArrowUp } from "lucide-react";
import { memo } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setFocusedSearchIndex } from "store/reducers/extract/CurrentExtractionReducer";
import tw from "twin.macro";
import { Requirement } from "types/Requirement";

interface DocumentSearchStepperProps {
  filteredRequirementsInActiveDocument: Requirement[];
}

const DocumentSearchStepper = ({ filteredRequirementsInActiveDocument }: DocumentSearchStepperProps) => {
  const dispatch = useAppDispatch();

  const focusedSearchIndex = useAppSelector((store) => store.currentExtractionState.focusedSearchIndex);

  const handleStep = (direction: "next" | "prev") => {
    if (direction === "next") {
      dispatch(setFocusedSearchIndex(focusedSearchIndex + 1));
    } else {
      dispatch(setFocusedSearchIndex(focusedSearchIndex - 1));
    }
  };

  return (
    <>
      <button
        className="p-1 disabled:text-gray-300 rounded-md ml-1"
        css={[!(focusedSearchIndex + 1 === filteredRequirementsInActiveDocument.length) && tw`hover:bg-gray-100`]}
        onClick={() => handleStep("next")}
        disabled={focusedSearchIndex + 1 === filteredRequirementsInActiveDocument.length}
      >
        <ArrowDown size={16} />
      </button>
      <button
        className="p-1 disabled:text-gray-300 rounded-md"
        css={[focusedSearchIndex !== 0 && tw`hover:bg-gray-100`]}
        onClick={() => handleStep("prev")}
        disabled={focusedSearchIndex === 0}
      >
        <ArrowUp size={16} />
      </button>
    </>
  );
};

export default memo(DocumentSearchStepper);
