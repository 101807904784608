import axios, { type AxiosHeaders } from "axios";
import { WorkspaceFile } from "types/FileStorage";
import type { InternalContract } from "types/InternalContract";
import type { TemplateDetails } from "types/Templates";

// ************ Auth *************

// Sign-in
export const signIn = (data: { email: string }, headers = {}) => axios.post("/users/login/", data, { headers });

// ************ Workspace *************

// Discover workspace
export const discoverWorkspace = () => axios.get("/users/workspace/discover");

// Create new workspace
export const createWorkspace = (
  data: {
    company_name: string;
    name: string;
    description: string;
  },
  headers = {},
) => axios.post("/users/workspace/discover", data, { headers });
// Get WorkspaceName
export const getWorkspaceNames = (name: string) => axios.post(`/users/workspace/validation`, { workspace_name: name });

// Get workspace members
export const getWorkspaceMembers = () => axios.get("/users/workspace/members");

// Update user details, username
export const updateUsername = (data: { username: string }) => axios.put("/users/details/", data);

// Get workspace details
export const getWorkspaceDetails = () => axios.get("/users/workspace/");

// Update particular workspace by admin of that workspace
export const updateUserWorkspace = (data: { company_name?: string; name?: string; description?: string }) =>
  axios.put("/users/workspace/", data);

// Send invite to a user to join our workspace
export const inviteInWorkspace = (data: { email: string }) => axios.post("/users/auth0/workspace/invite", data);

export const createIntegrationToken = (data: { email: string; workspace_id: string; partner_id: string }) =>
  axios.post("/integrations/auth/token/create", data);

export const fetchEnabledIntegrations = () => axios.get("/integrations/");
export const revokeIntegration = (partner_id: string) =>
  axios.post(`/integrations/auth/revoke`, { partner_id: partner_id });

// Remove a user from the workspace
export const removeUserWorkspace = (data: { member_id?: string }) =>
  axios.post("/users/workspace/members/remove", data);

// ************ Ask AI ***************

export const createNewSessionAPI = (data: { internal_contract_id: string }) => axios.post("/chat/session", data);

export type ChatSession = { id: string; name: string; created_at: string };
export const getAllChatSessionsAPI = (internal_contract_id: string): Promise<{ data: ChatSession[] }> =>
  axios.get(`/chat/session?internal_contract_id=${internal_contract_id}`);

export const editSessionNameAPI = (data: { session_id: string; name: string }) => axios.put(`/chat/session`, data);

export const deleteChatSessionAPI = (data: { session_id: string; internal_contract_id: string }) =>
  axios.delete(`/chat/session`, { data });

export const getChatMessagesForSessionAPI = (session_id?: string): Promise<{ data: ChatSession[] }> =>
  axios.get(`/chat/message?session_id=${session_id}`);

export const askAIAPI = (data: { content: string; session_id?: string }) => axios.post(`/chat/message`, data);

// ************ Proposal Assistant ***************

export const createNewProposalAssistantSessionAPI = (data: { internal_contract_id: string }) =>
  axios.post("/chat/session/corpus", data);

export const getAllProposalAssistantChatSessionsAPI = () => axios.get(`/chat/session/corpus`);

export const editProposalAssistantSessionNameAPI = (data: { session_id: string; name: string }) =>
  axios.put(`/chat/session/corpus`, data);

export const deleteProposalAssistantChatSessionAPI = (sessionId: string) =>
  axios.delete(`/chat/session/corpus?session_id=${sessionId}`);

export const getProposalAssistantChatMessagesForSessionAPI = (session_id: string) =>
  axios.get(`/chat/message/corpus?session_id=${session_id}`);

export const sendProposalAssistantChatMessageAPI = (data: { content: string; session_id: string }) =>
  axios.post(`/chat/message/corpus`, data);

export const getProposalAssistantDocs = () => axios.get("/corpus/document");

export const deleteProposalAssistantDocs = (data: { document_id: string }) =>
  axios.delete("/corpus/document", { data });

// ************ Contract **************

// Search contract & get contract list
export const contractSearch = (query = "") => axios.get(`/contracts/search${query}`);

// Add contract to contract manage
export const addToContract = (data: { contract_id?: string }) => axios.post("/contracts/internal", data);
// Delete Project
export const deleteInternalContract = (contract_id: string) => axios.delete(`/contracts/internal/${contract_id}/`);

// Mark as viewed contract
export const markAsViewedContract = (id: string) => axios.post(`/contracts/${id}/action/view`);

// Get dropdown options
export const getDropDownOptions = (query = "") => axios.get(`/contracts/search/filters${query}`);

// Get contract details
export const getContractDetails = (id: string) => axios.get(`/contracts/detail?id=${id}`);

// ************ Contract Manager **************

// Get contract manager list
export const getContractManagerItems = () => axios.get(`/contracts/internal`);

export const updateContractManagerItems = (data: {
  updates: Array<{
    internal_contract_id: string;
    starred: boolean;
    status: string;
    assigned_user_ids: string[];
    order_key: number;
    estimated_value: number;
  }>;
}) => axios.put(`/contracts/internal`, data);

// Upload new manually created contract
export const createPrivateInternalContract = (data: {
  client_address?: string;
  end_date?: string;
  opportunity_type?: string;
  response_date?: string;
  start_date?: string;
  title: string;
}) => axios.post(`/contracts/internal/private`, data);

// Get internal contract details
export const getInternalContractDetails = (id: string) => axios.get(`/contracts/internal/${id}/`);

// Edit internal contract details
export const updateInternalContractDetails = (id: string | undefined, data: InternalContract) =>
  axios.put(`/contracts/internal/${id}/`, data);

// Get internal contract details
export const uploadInternalContractLink = (
  id: string,
  data: {
    name: string;
    url: string;
  },
) => axios.post(`/contracts/internal/${id}/link/`, data);

// Get internal contract details
export const deleteInternalContractLink = (id: string) => axios.delete(`/contracts/internal/link/${id}/`);

// Create Milestone
export const createInternalContractMilestone = (
  id: string,
  data: {
    name: string;
    deadline: string;
  },
) => axios.post(`/contracts/internal/${id}/milestone/create`, data);

// Delete Milestone
export const deleteInternalContractMilestone = (milestone: string) =>
  axios.delete(`/contracts/internal/milestone/${milestone}/`);

// Delete Milestone
export const updateInternalContractMilestone = (
  milestone: string,
  data: {
    name: string;
    deadline: string;
  },
) => axios.put(`/contracts/internal/milestone/${milestone}/`, data);

// ************ Contract Document **************
// Delete a document from contract
export const deleteADoc = (id: string) => axios.delete(`/contracts/internal/document/${id}/`);

// ************ Notification **************

// Created saved search
export const saveSearchApi = (data: {
  name: string;
  filters: {
    search_query: string;
    departments: string[];
    naics_codes: string[];
    product_codes: string[];
    notice_types: string[];
    response_deadline: string | null;
    states: string[];
  };
}) => axios.post("/notifications/filters", data);

// Update saved search
type Filter = {};
export const updateSearchFiltersApi = (data: { id: string; name: string; feed: string; filters: Array<Filter> }) =>
  axios.put("/notifications/filters", data);

// Get saved search
export const getSaveSearchApi = () => axios.get("/notifications/filters");

// Delete Saved Search
export const getNotificationsApi = () => axios.get("/notifications/alerts");

export const getNotificationFeedsApi = () => axios.get("/notifications/feed");

// Queue an Assigned Requirement Notification
export const sendAssignedRequirementNotificationApi = (data: {
  requirement_id: string;
  internal_contract_id: string;
  assigned_user_id: string;
}) => axios.post("/notifications/notify/requirement", data);

// ************ Proposal Plan **************
export const getProposalPlanDocument = (id: string) => axios.get(`/contracts/internal/${id}/proposal/plan/documents`);
export const createProposalPlanDocument = (
  id: string,
  data: {
    is_directory: boolean;
    target_id: string;
  },
) => axios.post(`/contracts/internal/${id}/proposal/plan/documents`, data);
export const updateProposalPlanDocumentFilterState = (id: string, use_doc_filter: boolean) =>
  axios.put(`/contracts/internal/${id}/proposal/plan/documents`, { use_doc_filter });
export const deleteProposalPlanDocument = (
  id: string,
  data: {
    document_id: string;
  },
) => axios.delete(`/contracts/internal/${id}/proposal/plan/documents`, { data });
export const fetchInputDetails = (id: string) => axios.get(`/contracts/internal/${id}/proposal/plan/inputs/defaults`);
export const updateInputDetails = (
  id: string,
  data: {
    company_name: string;
    use_default_company_name: boolean;
  },
) => axios.put(`/contracts/internal/${id}/proposal/plan/inputs`, data);

// ************ Templates **************
export const getTemplates = () => axios.get("/templates/");
export const createTemplate = (data: {
  name?: string;
  data: {
    volumes: {
      name: string;
      sections: {
        name: string;
        subsections: {
          name: string;
        }[];
      }[];
    }[];
  };
}) => axios.post("/templates/", data);
export const getTemplateDetails = (id?: string) => axios.get(`/templates/${id}/`);
export const updateTemplateDetails = (id: string, data: Partial<TemplateDetails>) =>
  axios.put(`/templates/${id}/`, data);
export const deleteTemplate = (id: string) => axios.delete(`/templates/${id}/`);

// ************ Copilot **************

export const updateProposalName = (data: { proposal_name: string }, id: string) =>
  axios.put(`/contracts/internal/proposal/document/${id}/`, data);

export const deleteProposal = (id: string) => axios.delete(`/contracts/internal/proposal/document/${id}/`);

// Search file content.
export const expandFileContent = (file_content_id: string | null, previous: boolean) =>
  axios.post("/writing/search/full_text/expand", { file_content_id, previous });

// Get proposal status
export const getProposalStatus = (internalContractId: string) =>
  axios.post(`/proposal_generation/${internalContractId}/status`, {});

// Get proposal documents
export const getProposalDocuments = (internalContractId: string) =>
  axios.get(`/contracts/internal/${internalContractId}/proposal/documents`);

// Create new proposal document
export const createProposalDocument = (internalContractId: string, data: { proposal_name: string }) =>
  axios.post(`/contracts/internal/${internalContractId}/proposal/document`, data);

// Get compliance matrix excel generation
export const downloadExcel = (
  internalContractId: string | undefined,
  data: { requirements: string[]; columns: string[] },
) =>
  axios.post(`/contracts/internal/${internalContractId}/compliance_matrix/export_v2`, data, {
    responseType: "blob",
  });

// Get proposal document download
export const downloadProposal = (proposalDocumentId: string, dataFormat: "yjs") =>
  axios.get(`/contracts/internal/proposal/${proposalDocumentId}/export?data_format=${dataFormat}`, {
    responseType: "blob",
  });

/* Copilot - Generate Response  */

export const writingAssistant = (
  actionType: string,
  data: Partial<{
    text: string;
    tone: string;
    wordLimit: number;
    action: string;
    user_request: string;
    payloadAction: string;
  }>,
) => {
  const session_token = (localStorage.getItem("vultron_user_token") ?? "").replaceAll('"', "");
  const existingAttemptsJsonString = localStorage.getItem("past_attempts");
  const use_auth0 = localStorage.getItem("vultron_user_use_auth0");
  const useAuth0Header = use_auth0 === "true";
  const existingAttemptsArray = existingAttemptsJsonString && JSON.parse(existingAttemptsJsonString);

  return axios.post(`/writing/type/${actionType}`, {
    headers: {
      Authorization: `Bearer ${session_token}`,
      Workspace: `Workspace ${session_token}`,
      "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
    },
    text: data.text,
    past_attempts: data.payloadAction ? existingAttemptsArray : [data.text],
    tone: data.tone && data.tone,
    word_limit: data.wordLimit && data.wordLimit,
    user_request: data.user_request && data.user_request,
    action: data.action && data.action,
  });
};

////////////////////////// DRIVE API ////////////////////////////////
/////////////////////////////////////////////////////////////////////

export const getDirectories = () => axios.get(`file_storage/directory/view`);

export const getSingleDirectory = (directoryId: string) =>
  axios.get(`file_storage/directory/view?directory_id=${directoryId}`);

export const getAllDirectories = () => axios.get("file_storage/directory/all");

export const createDirectory = (data: { name: string; parent_directory_id: string | null }) =>
  axios.post("file_storage/directory/create", data);

export const updateDirectory = (directoryId: string, data: { name: string }) =>
  axios.put(`file_storage/directory/${directoryId}/`, data);

export const deleteDirectory = (directoryId: string) => axios.delete(`file_storage/directory/${directoryId}/`);

export const moveDirectory = (directoryId: string, data: { parent_directory_id: string | null }) =>
  axios.post(`file_storage/directory/move/${directoryId}/`, data);

export const createFile = (data: FormData, method: "upload", headers: AxiosHeaders) =>
  axios.post(`file_storage/file/create/${method}/`, data, { headers });

export const getFile = (fileId: string) => axios.get(`file_storage/file/${fileId}/`);

export const updateFile = (fileId: string, data: Partial<WorkspaceFile>) =>
  axios.put(`file_storage/file/${fileId}/`, data);

export const deleteFile = (fileId: string) => axios.delete(`file_storage/file/${fileId}/`);

export const deleteFiles = (fileIds: string[]) =>
  axios.post("file_storage/files/delete", {
    file_ids: Array.isArray(fileIds) ? fileIds : [fileIds],
  });

export const moveFile = (fileId: string, data: { parent_directory_id: string | null }) =>
  axios.post(`file_storage/file/move/${fileId}/`, data);

export const moveFiles = (fileIds: string[], data: { parent_directory_id: string | null }) => {
  const fileIdsArray = Array.isArray(fileIds) ? fileIds : [fileIds];
  return axios.post(`file_storage/files/move`, {
    file_ids: fileIdsArray,
    parent_directory_id: data.parent_directory_id,
  });
};
//Recommended tab

// get all cards data
export const recommendedCardsData = (type = "") => axios.get(`lead_recommendation/list/${type}`);

// update recommended card
export type RecommendedCardState = "converted" | "bookmarked" | "recommended" | "archived";
export const updateRecommendedCardData = (id = "", data: { state: RecommendedCardState }) =>
  axios.put(`lead_recommendation/${id}/update`, data);

// user feedback post
export const userFeedBackPost = (data: {
  lead_id?: string;
  feedback_type: "positive" | "negative";
  statement: string;
}) => axios.post(`lead_recommendation/feedback`, data);

//get user details for feedback
export const getUserFeedbackDetails = (id = "") => axios.get(`lead_recommendation/feedback?lead_id=${id}`);

// file storage all prompt file
export const getAllPromptFiles = (projectId: string, params: `&${string}`) =>
  axios.get(`/file_storage/file/all?bucket_id=${projectId}${params}`);

// scoring
export const getRequirementFeedback = (projectId: string | null | undefined, requirementId: string | null) =>
  axios.get(`/autopilot/${projectId}/score/requirement?requirement_id=${requirementId}`);

export const createRequirementFeedback = (projectId: string | null | undefined, requirementId: string) =>
  axios.post(`/autopilot/${projectId}/score/requirement`, { requirement_id: requirementId });

// review engine
export const cancelReviewEngine = (proposal_document_id?: string) =>
  axios.put(`/proposal_review_engine/${proposal_document_id}/cancel`);

// instant draft
export const cancelInstantDraft = async (projectId: string, analysisId: string) => {
  return await axios.put(`/autopilot/${projectId}/requirements/responses/${analysisId}/cancel`);
};
export const getInstantDraftQueuedTask = () => axios.get("/autopilot/requirements/task/total");
