export type VultronConfig = NonNullable<Window["VULTRON_CONFIG"]>;

const { REACT_APP_USE_LAUNCHDARKLY = "true", REACT_APP_BASE_URL = "" } = process.env;

const defaultConfig: VultronConfig = {
  apiUrl: REACT_APP_BASE_URL,
  isLaunchDarklyEnabled: !(REACT_APP_USE_LAUNCHDARKLY === "false" || REACT_APP_USE_LAUNCHDARKLY === "0"),
  uiFlags: {},
};

const config: VultronConfig = {
  ...defaultConfig,
  ...window.VULTRON_CONFIG,
};
export const { apiUrl, isLaunchDarklyEnabled } = config;
export default config;
