import { useRef, useState, Dispatch, SetStateAction } from "react";
import { BookMarkIcon } from "utils/icons";
import RecommendedSideBar from "./Sidebar";
import { type RecommendedCardState, updateRecommendedCardData } from "api/api";
import moment from "moment";
import { Button } from "components/editor/components";
import { useNotification } from "context/notificationContext";
import type { Card } from "../types";

interface Props {
  cardsData: Card[];
  setCardsData: Dispatch<SetStateAction<Card[]>>;
  tabName: string;
}

type UpdateCardFn = (id: string | undefined, state: RecommendedCardState, action?: () => void) => void;

const RecommendedCard = ({ cardsData, setCardsData, tabName }: Props) => {
  const { setToast } = useNotification();

  const sideBarRef = useRef<HTMLDivElement | null>(null);
  const [showSideBar, setShowSideBar] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);

  // Close sidebar drawer
  const handleCloseSideBar = () => {
    setSelectedCardIndex(null);
    setShowSideBar(false);
    if (sideBarRef.current) {
      sideBarRef.current.scrollTop = 0;
    }
  };

  // update state of recommended card
  const updateCard: UpdateCardFn = (id, state, action) => {
    const updatedCardsData = cardsData.map((card) => {
      if (card.id === id) {
        return { ...card, state };
      } else {
        return card;
      }
    });

    if (state === "converted") {
      setCardsData(updatedCardsData);
    } else if (
      (state === "bookmarked" && tabName === "recommended") ||
      (state === "recommended" && tabName === "recommended")
    ) {
      setCardsData(updatedCardsData);
    } else {
      setCardsData(updatedCardsData.filter((card) => card.id !== id));
    }

    updateRecommendedCardData(id, { state })
      .then(() => {
        if (state === "converted") {
          const foundCard = cardsData.find((card) => card.id === id);
          setToast.success({
            msg: `${foundCard?.title_name || ""} added to projects.`,
          });
        }
      })
      .catch(() => {
        if (state === "converted") {
          setToast.error({
            title: "Oops!",
            msg: "Something went wrong",
          });
        }
      })
      .finally(() => {
        if (action) {
          action();
        }
      });
  };

  //handling states when sidebar opens
  const handleSidebar = (ind: number) => {
    setShowSideBar(true);
    setSelectedCardIndex(ind);
  };

  return (
    <>
      <div className="max-w-full mx-3 my-4 flex gap-4 flex-wrap">
        {cardsData?.map((card: Card, ind: number) => (
          <div className="w-[49%] flex-shrink border rounded-lg border-[#DBE0E5] bg-[#fff] p-6" key={ind}>
            <div className="flex h-full flex-grow flex-col gap-5">
              <div className="flex items-start justify-between gap-4">
                <div className="flex flex-grow flex-col">
                  <span className="text-[#1D2630] text-sm font-medium leading-6 line-clamp-2">{card.title_name}</span>
                  <span className="text-[12px] font-normal leading-[22px] text-[#5B6B79] capitalize">
                    {card.client_name?.toLowerCase()}
                  </span>
                </div>
                <div className="flex select-none gap-2 items-center">
                  {card.new ? (
                    <div className="border whitespace-nowrap py-[0.15rem] px-[0.34rem] text-[11px] rounded border-[#2A47AB] text-[#2A47AB] bg-[#DEE5FF]">
                      New Today
                    </div>
                  ) : null}
                  {card.state === "bookmarked" ? (
                    <button onClick={() => updateCard(card.id, "recommended")}>
                      <BookMarkIcon width="24" height="26" fill="#F5C150" stroke="#F5C150" />{" "}
                    </button>
                  ) : (
                    <button onClick={() => updateCard(card.id, "bookmarked")}>
                      <BookMarkIcon width="24" height="26" />
                    </button>
                  )}
                </div>
              </div>
              <div className="flex justify-between w-[93%]">
                <div className="flex flex-col">
                  <span className="text-[#000] text-sm font-medium leading-6">
                    {card.publish_date ? moment(card.publish_date).format("MMMM DD, YYYY") : "N/A"}
                  </span>
                  <span className="text-[#5B6B79] text-[12px] font-normal leading-6">Published Date</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-[#000] text-sm font-medium leading-6">
                    {card.deadline ? moment(card.deadline).format("MMMM DD, YYYY") : "N/A"}
                  </span>
                  <span className="text-[#5B6B79] text-[12px] font-normal leading-6">Deadline</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-[#000] text-sm font-medium leading-6 capitalize">{card.stage || "N/A"}</span>
                  <span className="text-[#5B6B79] text-[12px] font-normal leading-6">Stage</span>
                </div>
              </div>
              {card.description ? (
                <div className="text-[#000] text-xs leading-5 font-normal line-clamp-5">{card.description}</div>
              ) : (
                <div className="flex my-auto py-5 justify-center items-center">
                  <span className="text-[#5B6B79] text-xs font-normal leading-[18px]">No overview available</span>
                </div>
              )}
              <div className="flex mt-auto flex-wrap gap-2">
                {card.formatted_tags?.length ? (
                  card.formatted_tags.map((tag: string, tagInd: number) => (
                    <span
                      className="px-2 select-none py-[1px] text-[#5B6B79] flex justify-center rounded-[4px] items-center bg-[#F1F1F1] text-xs font-normal leading-5"
                      key={tagInd}
                    >
                      {tag}
                    </span>
                  ))
                ) : (
                  <div className="flex flex-wrap gap-2 invisible">
                    <span className="px-2 select-none py-[1px] text-[#5B6B79] flex justify-center rounded-[4px] items-center bg-[#F1F1F1] text-xs font-normal leading-5">
                      Invisible area
                    </span>
                  </div>
                )}
              </div>
              <div className="flex justify-between pt-2 items-center">
                <div className="text-[#1D2630] text-sm font-normal leading-5">
                  <b>{card.match_score ? Math.round(card.match_score * 100) : 0}%</b> Estimated Requirements Satisfied
                </div>
                <div className="flex gap-6 items-center ">
                  <div className="text-gray-mid text-sm font-medium leading-[22px] duration-150 hover:text-gray-400">
                    {card.state === "archived" || tabName.toLowerCase() === "archived" ? (
                      <button onClick={() => updateCard(card.id, "recommended")}>Unarchive</button>
                    ) : (
                      <button onClick={() => updateCard(card.id, "archived")}>Archive</button>
                    )}
                  </div>
                  <Button size="md" variant="primary" onClick={() => handleSidebar(ind)}>
                    See more
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showSideBar ? (
        <div onClick={handleCloseSideBar} className="fixed bg-transparent z-[99] top-[65px] left-0 w-full h-full" />
      ) : null}
      <aside
        ref={sideBarRef}
        style={{ height: "calc(100vh - 65px)" }}
        className={`overflow-y-auto z-[99] scrollbar-0 bg-white shadow-lg fixed top-[65px] right-0 border-[#DBE0E5] flex-col gap-4 transition duration-500 ease-in-out w-full max-w-[660px] ${
          !showSideBar ? "translate-x-[100%] pointer-events-none opacity-0" : "translate-x-[0] opacity-1 flex"
        }`}
      >
        {showSideBar ? (
          <RecommendedSideBar
            handleCloseSideBar={handleCloseSideBar}
            cardsData={cardsData}
            selectedCardIndex={selectedCardIndex}
            setSelectedCardIndex={setSelectedCardIndex}
            updateCard={updateCard}
            tabName={tabName}
          />
        ) : null}
      </aside>
    </>
  );
};

export default RecommendedCard;
