// copy text
export const copyToClipBoard = async (copyMe: string): Promise<undefined> => {
  try {
    await navigator.clipboard.writeText(copyMe);
  } catch (err) {
    console.log("error while copying something", err);
  }
};

// formate file size
export const formatBytes = (a: number, b = 2): string => {
  if (!+a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
};

// count words
export const wordCount = (text = ""): number => {
  let count = 0;
  const split = text?.split(" ") || "";
  for (let i = 0; i < split?.length; i++) {
    if (split[i] !== "") {
      count++;
    }
  }
  return count;
};

// Sort Data who's added recently
export function getSortByTransactionDate<K extends string>(
  a: { [key in K]: number | string },
  b: { [key in K]: number | string },
  key: K,
): number {
  if (a && b) {
    return new Date(b[key]).valueOf() - new Date(a[key]).valueOf();
  }
  console.log("Did not get array of objects to sorting data check your data and also sorting method....");
  return 0;
}

// email validator function
export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test((email || "").toLowerCase());
};

export const validateField = (val = "", length?: number): boolean => {
  return val.toString().trim().replace(/\s/g, "").length > (length || 0);
};

export function formateUsersList<T extends Record<string, any>>(list: T[] = [], obj: T, key: keyof T): T[] {
  const mySelf = list?.find((v) => v[key] === obj[key]);
  list = list.filter((v) => v[key] !== obj[key]);
  return mySelf ? [mySelf, ...list] : list;
}
