import { Subdirectory, WorkspaceFile, WorkspaceMedia } from "types/FileStorage";

type SelectedItems = string[] | Subdirectory | WorkspaceMedia | null;
type FileType = WorkspaceFile | WorkspaceMedia;

interface GetAvailableFoldersParams {
  allDirectories: Subdirectory[];
  selectedItems: SelectedItems;
  currentFiles?: FileType[];
  currentDirectory?: Subdirectory | null;
  moveType?: "file" | "directory";
}

// Helper function to get children folders recursively
const getChildrenFolders = (parentId: string, allDirectories: Subdirectory[]): string[] => {
  const children = allDirectories.filter((f) => f.parent_directory === parentId);
  return children.reduce(
    (acc: string[], child) => [...acc, child.id, ...getChildrenFolders(child.id, allDirectories)],
    [],
  );
};

export const getAvailableFolders = ({
  allDirectories,
  selectedItems,
  currentFiles,
  currentDirectory,
  moveType,
}: GetAvailableFoldersParams): Subdirectory[] => {
  const rootFolder: Subdirectory = {
    id: "",
    name: "Main Library",
    workspace: "",
    created_at: "",
    updated_at: "",
    created_by: "",
    parent_directory: null,
  };
  // For media type (has moveType specified)
  if (moveType) {
    let folders: Subdirectory[] = [];

    if (moveType === "directory") {
      const movingId = !Array.isArray(selectedItems) ? (selectedItems as Subdirectory).id : "";
      const movingFolder = allDirectories.find((f) => f.id === movingId);
      const folderChildrens = getChildrenFolders(movingId, allDirectories);

      folders = allDirectories.filter(
        (folder) =>
          folder.id !== movingId && // Not itself
          !folderChildrens.includes(folder.id) && // Not a child
          folder.id !== movingFolder?.parent_directory, // Not current parent
      );
    } else {
      folders = allDirectories.filter((folder) => folder.id !== currentDirectory?.id);
    }

    return currentDirectory ? [rootFolder, ...folders] : folders;
  }

  // For documents type (no moveType specified)
  return allDirectories.filter((folder) => {
    const movingId = Array.isArray(selectedItems) ? selectedItems[0] : selectedItems;

    // Check if this is a file
    const isFile = currentFiles?.some((file) => file.id === movingId);

    if (isFile) {
      // For files: exclude current parent
      const selectedFile = currentFiles?.find((file) => file.id === movingId);
      return folder.id !== selectedFile?.parent_directory;
    } else {
      // Handle folder movement
      const movingFolder = allDirectories.find((f) => f.id === movingId);
      const folderChildrens = getChildrenFolders(movingId as string, allDirectories);

      return (
        folder.id !== movingId && // Not itself
        !folderChildrens.includes(folder.id) && // Not a child
        folder.id !== movingFolder?.parent_directory // Not current parent
      );
    }
  });
};
