interface CreateDragHandlerOptions {
  id: string;
  selectedIds: string[];
  displayName: string;
  itemsName: string;
  onDragStart?: (draggedIds: string[]) => void;
}

const createDragPreview = (text: string): HTMLElement => {
  const dragPreview = document.createElement("div");
  dragPreview.className = "absolute -top-[1000px] p-2.5 bg-white border border-gray-light rounded text-sm";
  dragPreview.textContent = text;
  return dragPreview;
};

export const createDragHandler =
  ({ id, selectedIds, displayName, itemsName, onDragStart }: CreateDragHandlerOptions) =>
  (e: React.DragEvent) => {
    e.stopPropagation();

    const draggedIds = selectedIds.includes(id) ? selectedIds : [id];
    const previewText = draggedIds.length > 1 ? `${draggedIds.length} ${itemsName}` : displayName;

    const dragPreview = createDragPreview(previewText);
    document.body.appendChild(dragPreview);
    e.dataTransfer.setDragImage(dragPreview, 0, 0);
    e.dataTransfer.setData("text/plain", JSON.stringify(draggedIds));
    e.dataTransfer.effectAllowed = "move";

    setTimeout(() => {
      document.body.removeChild(dragPreview);
    }, 0);

    onDragStart?.(draggedIds);
  };
