/** @jsxImportSource @emotion/react */

import { DraggableAttributes } from "@dnd-kit/core";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { EllipsisVertical } from "lucide-react";
import { StepValue } from "../../../types";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSectionDropdownItems } from "../hooks";
import { ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { HTMLAttributes, forwardRef, memo, useMemo, useState } from "react";
import {
  setEditableTemplateRowState,
  setRequirementsTableScrollToState,
} from "store/reducers/extract/CurrentExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import Icon from "components/atoms/icons/Icon";
import tw, { theme } from "twin.macro";
import useExtractionOperations from "hook/useExtractionOperations";
import { isInstantDraftStarted } from "../../../utils";
import Tooltip from "components/atoms/tooltip";

interface Props extends HTMLAttributes<HTMLDivElement> {
  volumeId: string;
  section: FormattedSection;
  withOpacity?: boolean;
  isDragging?: boolean;
  dragProps?: { attributes?: DraggableAttributes; listeners?: SyntheticListenerMap };
}

const SubsectionRow = forwardRef<HTMLDivElement, Props>(
  ({ dragProps, isDragging, withOpacity, style, volumeId, section }, ref) => {
    const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);
    const [titleRef, setTitleRef] = useState<HTMLSpanElement | null>(null);
    const [titleContainerRef, setTitleContainerRef] = useState<HTMLDivElement | null>(null);
    const { setSectionName } = useExtractionOperations();
    const dispatch = useAppDispatch();
    const editableTemplateRowState = useAppSelector((store) => store.currentExtractionState.editableTemplateRowState);
    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const { items } = useSectionDropdownItems(section, volumeId, extraction?.id);
    const isReadOnly =
      extraction?.status === ExtractionStatus.Completed ||
      isInstantDraftStarted(extraction?.instantDraftConfig?.status);
    const isImportStep = extraction?.step === StepValue.Review;
    const isEditing = editableTemplateRowState.id === section.id;

    const shouldShowTootlipTitle = useMemo(
      () =>
        !isEditing &&
        !isDragging &&
        !!section.title &&
        (titleRef?.clientWidth || 0) >= (titleContainerRef?.clientWidth || 0),
      [isDragging, isEditing, titleContainerRef?.clientWidth, titleRef?.clientWidth, section.title],
    );

    return (
      <div
        ref={ref}
        className="ml-[28px] rounded-md"
        css={[
          isDragging && tw`!m-0 bg-white`,
          isDragging && isImportStep && tw`bg-layout-gray-light`,
          {
            boxShadow: isDragging ? theme`boxShadow.expanded` : "none",
            margin: isDragging ? theme`boxShadow.expanded` : "none",
            zIndex: isDragging ? "4" : "auto",
            opacity: withOpacity ? "0.3" : "1",
            pointerEvents: isEditing ? "none" : "auto",
            ...style,
          },
          isReadOnly && tw`ml-[18px]`,
        ]}
        {...dragProps?.attributes}
      >
        <div>
          <div>
            <div ref={setPortalRef} className="flex flex-col gap-1.5 select-none">
              <div
                className="cursor-default overflow-hidden relative group text-gray-darkest rounded-md"
                css={[!isReadOnly && tw`cursor-pointer`, isReadOnly && tw`overflow-visible`]}
              >
                <div className="flex flex-row pr-4 pl-2 gap-0.5 items-center">
                  {!isReadOnly && (
                    <div className="flex flex-row items-center opacity-0 group-hover:opacity-100">
                      <div
                        className="z-[1] bg-transparent rounded p-1 opacity-0 text-slate-500 hover:text-slate-900 hover:bg-gray-200 group-hover:opacity-100"
                        css={[{ cursor: isDragging ? "grabbing" : "grab" }, isDragging && tw`opacity-100`]}
                        {...dragProps?.listeners}
                      >
                        <Icon name="Draggable" className="w-3 h-3" />
                      </div>
                    </div>
                  )}
                  <Tooltip
                    contentProps={{ align: "start", side: "left", sideOffset: 20 }}
                    content={section.title}
                    disableHoverableContent
                    disabled={!shouldShowTootlipTitle}
                    delayDuration={800}
                  >
                    <div
                      className="relative text-xs flex flex-row gap-1.5 items-center w-[calc(100%-6px)]"
                      css={[isReadOnly && tw`w-[calc(100%+22px)]`]}
                      onClick={() => {
                        dispatch(
                          setRequirementsTableScrollToState({
                            sectionId: section.id,
                          }),
                        );
                      }}
                    >
                      <div
                        className="absolute -left-3 inset-y-0 w-0.5 bg-gray-200"
                        css={[isReadOnly && tw`opacity-100`, !isReadOnly && tw`group-hover:opacity-0`]}
                      />
                      {isEditing ? (
                        <>
                          <input
                            placeholder="Subsection title..."
                            autoFocus
                            onChange={(e) => {
                              dispatch(setEditableTemplateRowState({ localValue: e.target.value }));
                            }}
                            onKeyDown={(e) => {
                              if (e.code === "Enter") {
                                if (extraction?.id) {
                                  setSectionName(
                                    extraction.id,
                                    volumeId,
                                    section.id,
                                    editableTemplateRowState.localValue,
                                  );
                                }
                                setTimeout(
                                  () =>
                                    dispatch(
                                      setEditableTemplateRowState({
                                        id: "",
                                        localValue: "",
                                      }),
                                    ),
                                  100,
                                );
                              }
                            }}
                            onBlur={() => {
                              if (extraction?.id) {
                                setSectionName(
                                  extraction.id,
                                  volumeId,
                                  section.id,
                                  editableTemplateRowState.localValue,
                                );
                              }
                              setTimeout(
                                () =>
                                  dispatch(
                                    setEditableTemplateRowState({
                                      id: "",
                                      localValue: "",
                                    }),
                                  ),
                                100,
                              );
                            }}
                            value={editableTemplateRowState.localValue}
                            className="text-[13px] font-medium py-2 outline-none text-gray-darkest w-full bg-transparent"
                          />
                        </>
                      ) : (
                        <div className="text-[13px] py-2 w-full min-w-0">
                          <div ref={setTitleContainerRef} className="flex flex-row items-center">
                            <span
                              ref={setTitleRef}
                              className="font-medium whitespace-nowrap"
                              css={[!section.title.trim() && tw`text-gray-400`]}
                            >
                              {section.title || "Subsection title..."}
                            </span>
                          </div>
                          {!isEditing && (
                            <div
                              className="absolute bottom-0 right-0 top-0 to-transparent bg-gradient-to-l w-14 from-white"
                              css={[!isReadOnly && tw`group-hover:w-16 group-hover:from-50% group-hover:from-white`]}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </div>
                {!isReadOnly && (
                  <div
                    className="opacity-0 text-slate-700 text-sm flex items-center gap-1.5 absolute bottom-0 right-0 top-0 pl-2 pr-2 bg-white group-hover:opacity-100"
                    css={[isEditing && tw`hidden`]}
                  >
                    <DropdownMenu
                      portalProps={{ container: portalRef }}
                      contentProps={{ align: "end", css: tw`min-w-[120px]` }}
                      items={items}
                    >
                      <div className="">
                        <EllipsisVertical size={14} />
                      </div>
                    </DropdownMenu>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default memo(SubsectionRow);
