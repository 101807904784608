/** @jsxImportSource @emotion/react */

import { Info, LucideBookOpenText } from "lucide-react";
import { useMemo, useState } from "react";
import DocumentJumper from "./DocumentJumper";
import Popover from "components/atoms/popover";
import tw from "twin.macro";
import { useFlags } from "hook/useFlags";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { toggleAskAiOpen } from "store/reducers/extract/CurrentExtractionReducer";
import AskAiForm from "components/organisms/ask-ai-form";
import { useSelection } from "../SelectionContext";
import { RiSparklingFill } from "@remixicon/react";
import BulkFloatingPillActions from "../bulk-floating-pill-actions";
import "../../styles.css";

type Props = {
  isReadOnly: boolean;
  setHelpOpen: (val: boolean) => void;
};

const BottomRightActions = ({ isReadOnly, setHelpOpen }: Props) => {
  const [open, setOpen] = useState(true);
  const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const { selectedBlocks } = useSelection();
  const askAiOpen = useAppSelector((store) => store.currentExtractionState.askAiOpen);
  const askAiIdentifiedRequirementIds = useMemo(
    () => selectedBlocks?.map((req) => req.requirement.requirement.id),
    [selectedBlocks],
  );

  return (
    <div
      className="flex flex-col w-full max-h-[40%] z-[3] duration-300 bottom-0 max-h-auto pointer-events-none relative"
      css={[!askAiOpen && tw`max-h-0`]}
    >
      {!!selectedBlocks?.length && !isReadOnly && <BulkFloatingPillActions />}
      <div
        ref={setPortalRef}
        className="flex absolute whitespace-nowrap flex-row items-center gap-2 pb-2 pr-2 w-fit self-end pointer-events-auto bottom-full"
      >
        {flags.askAiExtraction && (
          <>
            {!isReadOnly && (
              <button
                onClick={() => {
                  setHelpOpen(true);
                }}
                className="text-gray-darkest h-8 rounded-md px-2 border border-gray-darkest bg-gray-300 text-sm backdrop-blur-lg shadow py-1.5 flex items-center gap-1.5 duration-100 hover:bg-gray-200"
              >
                <LucideBookOpenText size={16} /> Legend
              </button>
            )}
            <button
              onClick={() => dispatch(toggleAskAiOpen())}
              className="ask-ai-button text-white rounded-md px-2 text-sm backdrop-blur-lg shadow py-1.5 flex items-center gap-1.5 duration-100"
            >
              Ask Vultron <RiSparklingFill size={16} />
            </button>
          </>
        )}
        {!flags.askAiExtraction && (
          <Popover
            open={open}
            onOpenChange={setOpen}
            contentProps={{
              align: "end",
              css: tw`mx-0 bg-white/80 backdrop-blur-lg border-1.5 border-gray-300`,
              onInteractOutside: (e) => e.preventDefault(),
            }}
            defaultOpen
            portalProps={{ container: portalRef }}
            content={
              <div className="flex flex-col gap-2.5 p-3 min-w-[150px] m-1">
                <div className="text-xs font-medium text-gray-500 mb-1.5">Legend</div>
                <div className="flex items-center gap-4 justify-between flex-row">
                  <div className="bg-white">
                    <div className="border-2 bg-[rgba(14,120,8,.2)] border-[rgb(14,120,8)] w-3 h-3" />
                  </div>
                  <span className="text-xs font-medium">Assigned</span>
                </div>
                <div className="flex items-center gap-4 justify-between flex-row">
                  <div className="bg-white">
                    <div className="border-2 border-action bg-action/20 w-3 h-3" />
                  </div>
                  <span className="text-xs font-medium">Selected</span>
                </div>
                <div className="flex items-center gap-4 justify-between flex-row">
                  <div className="bg-white">
                    <div className="border-2 border-gray-400 bg-black/15 w-3 h-3" />
                  </div>
                  <span className="text-xs font-medium">Unselected</span>
                </div>
              </div>
            }
          >
            <div
              className="px-2 backdrop-blur-lg h-8 shadow py-1.5 flex flex-row gap-2 rounded-md items-center cursor-pointer text-white bg-black/70 duration-100 hover:bg-black"
              css={[open && tw`bg-black`]}
            >
              <Info size={14} />
              <span className="text-xs">Legend</span>
            </div>
          </Popover>
        )}
        <DocumentJumper />
      </div>
      {flags.askAiExtraction && <AskAiForm requirementIds={askAiIdentifiedRequirementIds} />}
    </div>
  );
};

export default BottomRightActions;
