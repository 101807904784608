/** @jsxImportSource @emotion/react */

import { memo, useEffect, useState } from "react";
import { useAppDispatch } from "store/storeTypes";
import { setSearchQuery } from "store/reducers/extract/CurrentExtractionReducer";

const DocumentSearch = () => {
  const dispatch = useAppDispatch();

  const [userQuery, setUserQuery] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setSearchQuery(userQuery));
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [userQuery, dispatch]);

  return (
    <input
      type="text"
      className="document-search__input w-full h-10 pl-3 pr-[165px] rounded-md shadow border border-slate-400 focus:border-slate-800 focus:outline-none"
      placeholder="Search text..."
      onChange={(e) => setUserQuery(e.target.value)}
      value={userQuery}
    />
  );
};

export default memo(DocumentSearch);
