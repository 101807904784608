import {
  closestCenter,
  CollisionDetection,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  getFirstCollision,
  MouseSensor,
  PointerSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { ComplianceMatrixRow, Extraction, Framework } from "components/copilot/CopilotSchemaTypes";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { insert, move } from "utils/array";
import { groupComplianceMatrix } from "./utils";
import { keyBy, throttle } from "lodash";
import { useAppDispatch } from "store/storeTypes";
import { setIsDragActive } from "store/reducers/drag-drop/DragDropReducer";
import useExtractionOperations from "hook/useExtractionOperations";
import { useParams } from "react-router-dom";

export const useDrag = ({
  isRequirementsStep,
  isImportStep,
  complianceMatrix,
  flattenedSections,
}: {
  isRequirementsStep: boolean;
  isImportStep: boolean;
  complianceMatrix?: ToImmutable<ComplianceMatrixRow>[];
  flattenedSections: ToImmutable<Framework["volumes"]>;
}) => {
  const { reorderRequirementsInTable } = useExtractionOperations();
  const { extractionId } = useParams();
  const dispatch = useAppDispatch();
  const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);
  const [clonedItems, setClonedItems] = useState<Record<string, ToImmutable<Extraction["compliance_matrix"]>> | null>(
    null,
  );
  const [groupedComplianceMatrix, setGroupedComplianceMatrix] = useState<
    Record<string, ToImmutable<Extraction["compliance_matrix"]>>
  >({});
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  useEffect(() => {
    if (!isRequirementsStep && !isImportStep) return;
    const grouped = groupComplianceMatrix(complianceMatrix || []);
    setGroupedComplianceMatrix(grouped);
  }, [complianceMatrix, isImportStep, isRequirementsStep]);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId !== null) {
        if (overId in groupedComplianceMatrix) {
          const containerItems = groupedComplianceMatrix[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId && containerItems.some((row) => row.requirement.id === container.id),
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeDragId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeDragId, groupedComplianceMatrix],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4,
      },
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setClonedItems(groupedComplianceMatrix);
      setActiveDragId(event.active.id);
      dispatch(setIsDragActive(true));
    },
    [groupedComplianceMatrix, dispatch],
  );

  const handleDragCancel = useCallback(() => {
    const grouped = groupComplianceMatrix(complianceMatrix || []);
    setGroupedComplianceMatrix(grouped);
    setActiveDragId(null);
    setClonedItems(null);
    dispatch(setIsDragActive(false));
  }, [complianceMatrix, dispatch]);

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const overId = over?.id;
      const activeRequirementId = active.id as string;

      if (overId) {
        const sections = flattenedSections.flatMap((v) => v.sections);
        const overContainerId =
          requirementIdToSectionIdMap[overId] || sections.find((section) => section.id === over?.id)?.id;

        if (!overContainerId || !extractionId) {
          handleDragCancel();
          return;
        }

        const droppableTargetContainerRequirements = groupedComplianceMatrix[overContainerId] || [];
        const indexOfNewPlacement = !droppableTargetContainerRequirements?.length
          ? 0
          : droppableTargetContainerRequirements?.findIndex((row) => row.requirement.id === overId);
        const indexToDrop = indexOfNewPlacement || 0;
        const addedPositionDifference = indexToDrop === 0 ? indexToDrop : indexToDrop + 1;
        console.log(indexToDrop, overContainerId, activeRequirementId);
        const reorderedSection = move(
          droppableTargetContainerRequirements || [],
          droppableTargetContainerRequirements?.findIndex((row) => row.requirement.id === active.id),
          indexToDrop,
        ).map((row, i) => ({
          ...row,
          requirement: {
            ...row?.requirement,
            section_order: i,
          },
        }));
        setGroupedComplianceMatrix((prev) => {
          recentlyMovedToNewContainer.current = true;

          return {
            ...prev,
            [overContainerId]: reorderedSection,
          };
        });

        reorderRequirementsInTable(extractionId, reorderedSection, overContainerId);
        // get the section it is in via overContainerId
        // get the index it is in via overContainerId
      }

      setActiveDragId(null);
      dispatch(setIsDragActive(false));
    },
    [dispatch, reorderRequirementsInTable, extractionId],
  );

  const allSections = useMemo(() => flattenedSections.flatMap((v) => v.sections), [flattenedSections]);
  const requirementIdToSectionIdMap = useMemo(() => {
    const entries = Object.entries(groupedComplianceMatrix);
    return entries.reduce<Record<string, string>>((acc, [sectionId, requirements]) => {
      requirements.forEach(({ requirement }) => {
        acc[requirement.id] = sectionId;
      });
      return acc;
    }, {});
  }, [groupedComplianceMatrix]);

  const handleDragOver = useMemo(
    () =>
      throttle(
        ({ active, over }: DragOverEvent) => {
          const overId = over?.id;
          const sections = flattenedSections.flatMap((v) => v.sections);

          if (!overId || active?.id === overId) {
            return;
          }

          // const overContainerId = sections.find((section) => section.id === over?.id)?.id;
          const overContainerId =
            requirementIdToSectionIdMap[overId] || sections.find((section) => section.id === over?.id)?.id;
          // sections.find((section) =>
          //   groupedComplianceMatrix[section.id]?.some(({ requirement }) => requirement.id === overId)
          // )?.id;
          // groupedRequirementsBySectionId[overId]?.proposal_reference?.section_id;
          const activeContainerId = requirementIdToSectionIdMap[active.id];
          // sections.find((section) =>
          //   groupedComplianceMatrix[section.id]?.some(({ requirement }) => requirement.id === active?.id)
          // )?.id;
          // groupedRequirementsBySectionId[active.id]?.proposal_reference?.section_id;
          // console.log(over);

          if (!overContainerId || !activeContainerId || overContainerId === activeContainerId) {
            return;
          }

          const isDroppingIntoDifferentSection = activeContainerId !== overContainerId;
          if (isDroppingIntoDifferentSection) {
            // console.log(
            //   //  collisions,
            //   //  active,
            //   //  over,
            //   //  keyBy(sections, "id"),
            //   //  sections.find((section) => section.id === active?.id),
            //   //  sections.find((section) => section.id === over?.id),
            //   groupedComplianceMatrix[activeContainerId]?.length,
            //   groupedComplianceMatrix[overContainerId]?.length,
            // );

            const droppableTargetContainerRequirements = groupedComplianceMatrix[overContainerId] || [];
            const indexOfNewPlacement = !droppableTargetContainerRequirements?.length
              ? 0
              : droppableTargetContainerRequirements?.findIndex((row) => row.requirement.id === overId);
            const indexToDrop =
              droppableTargetContainerRequirements[indexOfNewPlacement]?.requirement?.section_order ||
              indexOfNewPlacement ||
              0;

            const lastRequirementInDroppableSection = droppableTargetContainerRequirements?.at(-1);
            const newSectionOrder =
              lastRequirementInDroppableSection?.requirement?.section_order || lastRequirementInDroppableSection || 0;
            const requirementToInsert = groupedComplianceMatrix[activeContainerId].find(
              ({ requirement }) => requirement.id === active.id,
            );
            const requirementWithUpdatedSectionOrder = {
              ...requirementToInsert,
              requirement: {
                ...requirementToInsert?.requirement,
                section_order: indexToDrop === 0 ? indexToDrop : indexToDrop + 1,
              },
            };
            console.log(indexToDrop, newSectionOrder);

            setGroupedComplianceMatrix((prev) => {
              recentlyMovedToNewContainer.current = true;

              return {
                ...prev,
                [activeContainerId]: groupedComplianceMatrix[activeContainerId].filter(
                  ({ requirement }) => requirement.id !== active.id,
                ),
                [overContainerId]: insert(
                  droppableTargetContainerRequirements || [],
                  requirementWithUpdatedSectionOrder,
                  indexToDrop === 0 ? indexToDrop : indexToDrop + 1,
                ),
              };
            });
          }
        },
        20,
        { leading: false, trailing: true },
      ),
    [groupedComplianceMatrix, requirementIdToSectionIdMap, allSections],
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [groupedComplianceMatrix]);

  return {
    collisionDetectionStrategy,
    sensors,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    activeDragId,
    clonedItems,
    handleDragOver,
    groupedComplianceMatrix,
  };
};
