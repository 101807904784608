import { useEffect, useState } from "react";
import axios from "axios";

import type { Canceler } from "axios";
import type React from "react";

type Result = unknown;

export const useSearch = (
  url: string,
  firstReload: boolean,
  dataKey: string,
  infiniteScroll: boolean,
  setHasMore?: (remaining: boolean) => unknown,
  offset?: number,
): { result: Result[]; loadingResult: boolean; setResult: React.Dispatch<React.SetStateAction<Result[]>> } => {
  const [result, setResult] = useState<Result[]>([]);
  const [loadingResult, setLoadingResult] = useState(true);

  useEffect(() => {
    if (firstReload) return;
    if (!infiniteScroll || !offset) {
      setResult([]);
    }
    setLoadingResult(true);
    let cancel: Canceler;
    axios({
      method: "GET",
      url: url,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        const data = dataKey ? res.data[dataKey] : res.data;
        if (infiniteScroll) {
          setResult((prev) => {
            return Array.from(new Set([...prev, ...data]));
          });
          if (setHasMore) setHasMore(data?.length > 49);
        } else {
          setResult(Array.from(new Set([...data])));
        }
        setLoadingResult(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        console.log("Error while searching data", err);
      });
    return () => cancel();
  }, [url]);

  return { result, loadingResult, setResult };
};
