import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useFlags } from "./useFlags";
import { CopilotPresencePage } from "types/Presence";
import { CAPTURE_BASE_PATH } from "pages/capture/constants";
import { PROJECT_SPECIFIC_BASE_PATH } from "components/copilot/ProposalPlan/project-specific/constants";

export const useMaintainLocation = () => {
  const flags = useFlags();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");
  const id = searchParams.get("id")?.toLocaleLowerCase();

  const validTab =
    tab !== CopilotPresencePage.Project &&
    Object.values(CopilotPresencePage).includes(tab as CopilotPresencePage) &&
    tab;

  useEffect(() => {
    if (validTab) localStorage.setItem("vultron_last_page", validTab);
  }, [validTab]);

  useEffect(() => {
    if (!flags.workspaceNavigation) return;

    const lastPage = localStorage.getItem("vultron_last_page");

    if (lastPage && id) {
      if (lastPage === CopilotPresencePage.Capture) {
        navigate(`${CAPTURE_BASE_PATH}/customer?tab=${lastPage}&id=${id}`);
      } else if (lastPage === CopilotPresencePage.Documents) {
        navigate(`${PROJECT_SPECIFIC_BASE_PATH}?tab=${lastPage}&id=${id}`);
      } else {
        navigate(`/dashboard/contracts/details?tab=${lastPage}&id=${id}`);
      }
      localStorage.setItem("hasVisitedBefore", "true");
    }
  }, []);
};
