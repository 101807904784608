import { FeatureFlagSet } from "types/FeatureFlagSet";

export const STATIC_FEATURE_FLAG_MAP: FeatureFlagSet = {
  allowURLParams: false,
  askAiExtraction: true,
  capture: true,
  cmTemplates: true,
  comments: true,
  disableExtractionFromTextInRequirements: true,
  disableGenerateSections: true,
  disableIntercomMessenger: true,
  disableInternetChat: true,
  disableTemplateTabInGenerate: true,
  documentSearch: true,
  emergencyYjs: false,
  enableCancelReviewEngine: true,
  enableOnsiteFeatures: true,
  estimatedTimeMultiplicationFactor: 2,
  instantDraft: false,
  instantDraftFromGeneration: true,
  liveTemplateGeneration: true,
  maxWritingDirections: 10,
  newFeatureBanner: {},
  qualityResponse: true,
  redTeam: false,
  reviewEngine: true,
  textOutlineEditor: true,
  topBannerV2: { bannerCopy: "" },
  useIndexed: true,
  workspaceNavigation: true,
};
