import { ReactComponent as ArrowRightNav } from "Assets/svgs/arrow-right-nav.svg";
import Tabs from "components/organisms/tabs";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { setDocumentNavHistory, setMediaNavHistory } from "store/reducers/driveReducerSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { usePollDrive, useTabs } from "./hooks";
import { TabSlug } from "./types";
import { useRef } from "react";

const Drive = () => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const {
    activeTabState: [activeDriveTab, setActiveDriveTab],
    driveTabs,
  } = useTabs();
  usePollDrive();

  const dispatch = useAppDispatch();
  const { navHistory: docNavHistory } = useAppSelector((root) => root.drive.fileStorage);
  const { navHistory: mediaNavHistory } = useAppSelector((root) => root.drive.media);

  return (
    <div className="overflow-hidden flex flex-col bg-white h-[100%]">
      <div className="flex border-b justify-between border-[#ebeced] mb-4 items-center shadow-sm bg-white pl-6">
        <Tabs<TabSlug>
          className="!border-b-0 !gap-4 ml-[-6]"
          tabs={driveTabs}
          activeTab={activeDriveTab}
          onTabSelect={(tab) => {
            if (activeDriveTab.slug === TabSlug.Media) {
              dispatch(setMediaNavHistory([]));
            } else if (activeDriveTab.slug === TabSlug.Documents) {
              dispatch(setDocumentNavHistory([]));
            }
            setActiveDriveTab(tab);
            navigate(`${tab.slug}`);
          }}
        />
        {activeDriveTab.slug === TabSlug.Media && (
          <div className="mx-6 flex items-center gap-2 justify-center">
            {mediaNavHistory.length > 0 && (
              <Link
                className="text-gray-text hover:underline"
                to={`/dashboard/library/media`}
                onClick={() => dispatch(setMediaNavHistory([]))}
              >
                Drive
              </Link>
            )}
            {mediaNavHistory.map((item, index) => (
              <div className="flex items-center gap-2 justify-center" key={index}>
                <ArrowRightNav />
                <Link
                  className="text-gray-text hover:underline"
                  to={`/dashboard/library/media/folder/${item.id}`}
                  onClick={() => {
                    dispatch(setMediaNavHistory(mediaNavHistory.slice(0, index + 1)));
                  }}
                >
                  {item.name}
                </Link>
              </div>
            ))}
          </div>
        )}

        {activeDriveTab.slug === TabSlug.Documents && (
          <div className="mx-6 flex items-center gap-2 justify-center">
            {docNavHistory.length > 0 && (
              <>
                <Link
                  className="text-gray-text hover:underline"
                  to={`/dashboard/library/documents`}
                  onClick={() => dispatch(setDocumentNavHistory([]))}
                >
                  Drive
                </Link>
                {docNavHistory.map((item, index) => (
                  <div className="flex items-center gap-2 justify-center" key={index}>
                    <ArrowRightNav />
                    <Link
                      className="text-gray-text hover:underline"
                      to={`/dashboard/library/documents/folder/${item.id}`}
                      onClick={() => {
                        dispatch(setDocumentNavHistory(docNavHistory.slice(0, index + 1)));
                      }}
                    >
                      {item.name}
                    </Link>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>

      <div className="overflow-y-auto px-6 select-none" ref={scrollContainerRef}>
        <Outlet context={{ scrollContainerRef }} />
      </div>
    </div>
  );
};

export default Drive;
