/** @jsxImportSource @emotion/react */

import DropdownMenu, { DropdownMenuProps } from "components/molecules/dropdown-menu";
import { Wand2 } from "lucide-react";
import { useVultronAiOptions } from "./hooks";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import { useAppSelector } from "store/storeTypes";
import "../../styles.css";

const VultronAiDropdown = <T,>(props: Omit<DropdownMenuProps<T>, "items" | "multiselect">) => {
  const vultronAiOptions = useVultronAiOptions(props.portalProps?.container);
  const isLoading = useAppSelector((store) => store.liveTemplateExtractionState.isLoadingLiveTemplateExtraction);

  return (
    <DropdownMenu contentProps={{ side: "top", sideOffset: 10 }} items={vultronAiOptions} {...props}>
      <div className="ask-ai-button text-xs flex items-center gap-2 truncate max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 hover:bg-black disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-transparent disabled:border-gray-500">
        {isLoading ? <SpinnerCircle className="h-3.5 w-3.5" /> : <Wand2 size={14} />}
        Vultron AI
      </div>
    </DropdownMenu>
  );
};

export default VultronAiDropdown;
