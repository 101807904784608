const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const addWeeks = (date: Date, weeks: number): Date => addDays(date, weeks * 7);

const addMonths = (date: Date, months: number): Date => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
};

const addYears = (date: Date, years: number): Date => {
  const result = new Date(date);
  result.setFullYear(result.getFullYear() + years);
  return result;
};

type FutureDateOption =
  | "Tomorrow"
  | "Within the next week"
  | "Within the next month"
  | "Within the next 6 months"
  | "Within the next year";

export const getFutureDate = (e: FutureDateOption | string): Date => {
  let d = addDays(new Date(), 1);

  switch (e) {
    case "Tomorrow":
      d = addDays(new Date(), 1);
      break;
    case "Within the next week":
      d = addWeeks(new Date(), 1);
      break;
    case "Within the next month":
      d = addMonths(new Date(), 1);
      break;
    case "Within the next 6 months":
      d = addMonths(new Date(), 6);
      break;
    case "Within the next year":
      d = addYears(new Date(), 1);
      break;
    default:
      d = addDays(new Date(), 1);
  }

  return d;
};

type PastDateOption = "Yesterday" | "Last Week" | "Last Month" | "Last 6 Months" | "Last Year";

export const getPastDate = (e: PastDateOption | string): Date => {
  let d = addDays(new Date(), -1);

  switch (e) {
    case "Yesterday":
      d = addDays(new Date(), -1);
      break;
    case "Last Week":
      d = addWeeks(new Date(), -1);
      break;
    case "Last Month":
      d = addMonths(new Date(), -1);
      break;
    case "Last 6 Months":
      d = addMonths(new Date(), -6);
      break;
    case "Last Year":
      d = addYears(new Date(), -1);
      break;
    default:
      d = addDays(new Date(), -1);
  }

  return d;
};

type DateType = "future" | "past";

interface Option {
  name: string;
  value: string;
}

export const getExactDate = (
  dateString: string[] = [""],
  listToCheck: Option[] = [],
  type: DateType = "future",
): string => {
  const dt = dateString || [];
  const thatObj = listToCheck?.find((opt) => opt?.name === dt[0]);
  let resp = dt[0];

  if (dt && thatObj?.name) {
    const date = type === "past" ? getPastDate(thatObj.value) : getFutureDate(thatObj.value);

    resp = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }

  return resp || "";
};
