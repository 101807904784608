import { pluralizeWord } from "utils/string";

export interface ReviewStatus {
  needsReview: boolean;
  color?: string;
  text?: string;
  tooltipText?: string;
}

const getToolTip = (dayDifference: number) => {
  if (dayDifference < 0) {
    const absDayDifference = Math.abs(dayDifference);
    return `The review date for this document has passed by ${absDayDifference} ${pluralizeWord(
      absDayDifference,
      "day",
    )}. Please review immediately and adjust the review date.`;
  } else if (dayDifference === 0) {
    return "The review date for this document is today. Please review immediately and adjust the review date";
  } else {
    return `The review date for this document is ${dayDifference} ${pluralizeWord(
      dayDifference,
      "day",
    )} away. Please review soon and adjust the review date`;
  }
};

export const getReviewStatus = (date: string | null): ReviewStatus => {
  if (!date) {
    return { needsReview: false };
  }

  const now = new Date();
  const reviewDate = new Date(date);
  const diffTime = reviewDate.getTime() - now.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays <= 4) {
    return { needsReview: true, color: "text-red-500", text: "Needs Review", tooltipText: getToolTip(diffDays) };
  } else if (diffDays < 7 && diffDays > 4) {
    return { needsReview: true, color: "text-yellow-500", text: "Needs Review", tooltipText: getToolTip(diffDays) };
  }

  return { needsReview: false };
};
