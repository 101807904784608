import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { writingAssistant } from "api/api";

type UserResponse = {
  action?: string;
  actionType: string;
  payloadAction: string;
  text?: string;
  tone?: string;
  user_request?: string;
};
type AttemptItem = unknown;
type UserPayload = {
  data: {
    new_attempts: Array<AttemptItem>;
  };
  action: UserResponse["action"];
  payloadAction?: UserResponse["payloadAction"];
};

export const sendingUserResponse = createAsyncThunk<UserPayload, UserResponse>("sendingUserResponse", async (obj) => {
  try {
    const { actionType, text, payloadAction, tone, action, user_request } = obj;
    const response = await writingAssistant(actionType, { text, tone, action, user_request, payloadAction });
    let payload;
    if (payloadAction) {
      payload = { data: response.data, action, payloadAction };
    } else {
      payload = { data: response.data, action };
    }
    return payload && payload;
  } catch (error) {
    // Handle error
    console.error((error as { error_msg: string }).error_msg);
    throw error;
  }
});

const initialState: {
  changeToneToggle: boolean;
  isSearchBarOpen: boolean;
  isTextAreaHasValue: boolean;
  isExpanded: boolean;
  isTypingDone: boolean;
  selectedText: string;
  editorResponse: { new_attempts?: AttemptItem[]; response?: string };
  askAiToggle: boolean;
  loading: boolean;
  lastActionState: {
    actionType?: string;
    text?: string;
    user_request?: string;
  };
} = {
  changeToneToggle: false,
  isSearchBarOpen: false,
  isTextAreaHasValue: false,
  isExpanded: false,
  isTypingDone: false,
  selectedText: "",
  editorResponse: { new_attempts: [], response: "" },
  askAiToggle: false,
  loading: false,
  lastActionState: {},
};

export const aiReducerSlice = createSlice({
  name: "aiReducer",
  initialState,
  reducers: {
    changeToneToggleAction: (state, action) => {
      state.changeToneToggle = action.payload;
    },
    askAiAction: (state, action) => {
      state.askAiToggle = action.payload;
    },
    isTypingDoneAction: (state, action) => {
      state.isTypingDone = action.payload;
    },
    isTextAreaHasValueAction: (state, action) => {
      state.isTextAreaHasValue = action.payload;
    },

    selectedTextAction: (state, action) => {
      state.selectedText = action.payload;
    },
    isExpandedAction: (state) => {
      state.isExpanded = !state.isExpanded;
    },
    resetSelectedText: (state) => {
      state.selectedText = "";
    },
    resetEditorResponse: (state) => {
      state.editorResponse = { response: "" };
    },
    isSearchBarOpenAction: (state, action) => {
      state.isSearchBarOpen = action.payload;
    },
    tryAgainAction: (state, action) => {
      state.lastActionState = action.payload;
    },
    resetTryAgainAction: (state) => {
      state.lastActionState = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendingUserResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendingUserResponse.fulfilled, (state, action) => {
        state.loading = false;
        state.editorResponse = action.payload.data;

        if (action.payload.payloadAction === "try-again" && action.payload.data.new_attempts) {
          const newAttemptItem = action.payload.data.new_attempts[action.payload.data.new_attempts?.length - 1];
          if (!newAttemptItem) {
            return;
          }

          const existingAttemptsJsonString = localStorage.getItem("past_attempts");
          const existingAttemptsArray = existingAttemptsJsonString
            ? (JSON.parse(existingAttemptsJsonString) as Array<AttemptItem>)
            : [];

          // Check if newAttemptItem already exists in existingAttemptsArray
          const isNewAttemptAlreadyExists = existingAttemptsArray.some((item) => item === newAttemptItem);

          if (!isNewAttemptAlreadyExists) {
            existingAttemptsArray.push(newAttemptItem);
            const newAttemptsJsonString = JSON.stringify(existingAttemptsArray);
            localStorage.setItem("past_attempts", newAttemptsJsonString);
          }
        } else if (action.payload.data.new_attempts) {
          const newAttemptItem = action.payload.data.new_attempts;
          const newAttemptsJsonString = JSON.stringify(newAttemptItem);
          localStorage.setItem("past_attempts", newAttemptsJsonString);
        }
      })
      .addCase(sendingUserResponse.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  changeToneToggleAction,
  askAiAction,
  resetEditorResponse,
  selectedTextAction,
  isSearchBarOpenAction,
  tryAgainAction,
  isTypingDoneAction,
  isExpandedAction,
  resetTryAgainAction,
  resetSelectedText,
  isTextAreaHasValueAction,
} = aiReducerSlice.actions;

export default aiReducerSlice.reducer;
