/** @jsxImportSource @emotion/react */
import type React from "react";
import { Checkbox } from "components/atoms/checkbox";
import Icon from "components/atoms/icons/Icon";
import { InstantDraftConfig, InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import { Button } from "components/editor/components";
import StrictnessDropdown from "components/molecules/strictness-dropdown";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { STRICTNESS_TO_META } from "const-values/Draft";
import useExtractionOperations from "hook/useExtractionOperations";
import xor from "lodash/xor";
import { ExternalLink } from "lucide-react";
import { LiveList, ToImmutable } from "YJSProvider/LiveObjects";
import { useGenerateInstantDraft } from "./hooks";
import { ResponseTolerance } from "types/Requirement";
import { Link, useParams, useSearchParams } from "react-router-dom";
import tw, { theme } from "twin.macro";
import { useMemo, useRef } from "react";
import { StepValue } from "../types";
import { CopilotPresencePage } from "types/Presence";
import useGetForms from "hook/capture/useGetForms";
import { uniqBy } from "lodash";
import { CaptureSchemaType } from "types/Capture";
import { useFlags } from "hook/useFlags";
import Tooltip from "components/atoms/tooltip";
import { YJS_OPERATIONS } from "const-values/yjs";

type Props = {
  instantDraftConfig?: ToImmutable<InstantDraftConfig>;
  isReadOnly: boolean;
};

const InstantDraftConfigForm = ({ instantDraftConfig, isReadOnly }: Props) => {
  const [searchParams] = useSearchParams();
  const flags = useFlags();
  const { data: captureForms } = useGetForms(undefined, { enabled: flags.instantDraftFromGeneration });
  const projectId = searchParams.get("id");
  const containerRef = useRef(null);
  const { extractionId } = useParams();
  const { updateInstantDraftConfig, updateExtractionStep, addAttribution } = useExtractionOperations();
  const { generateInstantDraft, canSubmit, isLoading } = useGenerateInstantDraft();
  const strictness = instantDraftConfig?.strictness || ResponseTolerance.Strict;
  const confirmations = useMemo(() => instantDraftConfig?.confirmations || [], [instantDraftConfig?.confirmations]);
  const StrictnessIcon = STRICTNESS_TO_META[strictness].icon;

  const totalFormQuestions = useMemo(
    () =>
      uniqBy(
        captureForms
          .filter(({ schema }) => schema.type !== CaptureSchemaType.PastPerformance)
          .flatMap(({ items }) => items),
        "question_id",
      ),
    [captureForms],
  );

  const filledOutQuestions = useMemo(
    () => totalFormQuestions.filter(({ response }) => !!response?.value?.trim()).length,
    [totalFormQuestions],
  );

  const captureCompletionState = useMemo(() => {
    const percentFilledOut = Math.ceil((filledOutQuestions / totalFormQuestions.length) * 100) || 0;
    const state = {
      completion: percentFilledOut,
      color: theme`colors.gray.darkest`,
    };

    if (percentFilledOut >= 80) state.color = theme`colors.green.500`;
    else if (percentFilledOut >= 60) state.color = theme`colors.yellow.600`;
    else if (percentFilledOut >= 40) state.color = theme`colors.orange.500`;
    else state.color = theme`colors.red.500`;

    return state;
  }, [filledOutQuestions, totalFormQuestions.length]);

  const isGenerateDisabled =
    !canSubmit ||
    !!xor(["documents", "capture"], confirmations).length ||
    instantDraftConfig?.status === InstantDraftStatus.Pending;

  return (
    <div
      ref={containerRef}
      className="min-h-0 bg-gray-lightest border border-gray-light rounded-md pl-8 pt-8 pr-4 pb-4 gap-6 flex flex-col"
    >
      <div className="text-gray-darkest text-xl font-medium">2. Configure Instant Draft </div>
      <div className="flex flex-col items-start gap-4">
        <div className="text-gray-darkest text-base font-medium">Select Strictness Level</div>
        <StrictnessDropdown
          disabled={isReadOnly}
          contentProps={{ align: "start" }}
          portalProps={{ container: containerRef.current }}
          setStrictness={(val) => {
            if (extractionId) updateInstantDraftConfig(extractionId, { strictness: val });
            addAttribution(YJS_OPERATIONS.EXTRACTION.SET_INSTANT_DRAFT_STRICTNESS);
          }}
          selectedStrictness={strictness}
        >
          <div
            className="flex items-center gap-1.5 bg-[#EFEFEF] border border-gray-light text-gray-darkest rounded-md text-sm pl-3 pr-2 py-2 duration-100 hover:text-slate-900 hover:bg-[#e2e2e2]"
            css={[isReadOnly && tw`pointer-events-none opacity-50`]}
          >
            {<StrictnessIcon className="w-3.5 h-3.5 shrink-0" />}
            {STRICTNESS_TO_META[strictness].label}
            <Icon name="CarrotDown" className="shrink-0 ml-2" />
          </div>
        </StrictnessDropdown>
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-gray-darkest text-base font-medium">Confirm the following information</div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-row items-start gap-2">
            <Checkbox
              variant="primaryBlack"
              disabled={isReadOnly}
              onCheck={() => {
                if (extractionId)
                  updateInstantDraftConfig(extractionId, {
                    confirmations: new LiveList(xor(confirmations, ["documents"])),
                  });
                addAttribution(YJS_OPERATIONS.EXTRACTION.SET_INSTANT_DRAFT_CONFIRMATION);
              }}
              checked={confirmations.includes("documents")}
            />{" "}
            <div className="flex flex-col gap-2 items-start">
              <div className="text-sm text-gray-darkest">Please confirm documents and content library</div>
              <div className="flex flex-row items-center gap-1.5">
                <Link
                  to={`/dashboard/contracts/details/documents/project-specific?tab=${CopilotPresencePage.Documents}&id=${projectId}`}
                  className="py-1 pl-2.5 pr-3 border border-gray-ligt text-xs text-gray-mid flex items-center gap-2 bg-[#EFEFEF] rounded-md duration-100 hover:bg-[#e2e2e2]"
                  target="_blank"
                >
                  Documents
                  <ExternalLink size={12} />
                </Link>
                <Link
                  to={`/dashboard/contracts/details/documents/content-library?tab=${CopilotPresencePage.Documents}&id=${projectId}`}
                  className="py-1 pl-2.5 pr-3 border border-gray-ligt text-xs text-gray-mid flex items-center gap-2 bg-[#EFEFEF] rounded-md duration-100 hover:bg-[#e2e2e2]"
                  target="_blank"
                >
                  Content Library
                  <ExternalLink size={12} />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start gap-2">
            <Checkbox
              variant="primaryBlack"
              disabled={isReadOnly}
              onCheck={() => {
                if (extractionId)
                  updateInstantDraftConfig(extractionId, {
                    confirmations: new LiveList(xor(confirmations, ["capture"])),
                  });
                addAttribution(YJS_OPERATIONS.EXTRACTION.SET_INSTANT_DRAFT_CONFIRMATION);
              }}
              checked={confirmations.includes("capture")}
            />{" "}
            <div className="flex flex-col gap-2 items-start">
              <div className="text-sm text-gray-darkest">
                Please confirm capture is complete <span className="text-gray-400">|</span>{" "}
                <span className="font-semibold" css={{ color: captureCompletionState.color }}>
                  {captureCompletionState.completion}%
                </span>{" "}
                completed
              </div>
              <Link
                to={`/dashboard/contracts/details/capture?tab=${CopilotPresencePage.Capture}&id=${projectId}`}
                className="py-1 pl-2.5 pr-3 border border-gray-ligt text-xs text-gray-mid flex items-center gap-2 bg-[#EFEFEF] rounded-md duration-100 hover:bg-[#e2e2e2]"
                target="_blank"
              >
                Capture
                <ExternalLink size={12} />
              </Link>
            </div>
          </div>
        </div>
        {(!isReadOnly || instantDraftConfig?.status === InstantDraftStatus.Pending) && (
          <div className="flex flex-row gap-2 w-full justify-end">
            <Button
              disabled={instantDraftConfig?.status === InstantDraftStatus.Pending || isLoading}
              onClick={() => {
                if (extractionId) updateExtractionStep(extractionId, StepValue.Review);
              }}
              className="!border-gray-darkest !text-gray-darkest !bg-transparent hover:!bg-gray-100"
              variant="outline"
              size="md"
            >
              Skip
            </Button>
            <Tooltip
              contentProps={{ align: "end", style: tw`mx-0` as React.CSSProperties }}
              content={
                <div className="flex flex-col gap-2">
                  Please confirm that all documents and capture intelligence are set up and completed for optimal
                  results. These steps are important for generating a detailed and comprehensive proposal draft.
                </div>
              }
              disabled={!isGenerateDisabled && !isLoading}
            >
              <Button
                onClick={() => {
                  triggerConfirm({
                    header: "Confirm Initiation of Instant Draft Generation",
                    body: "You will no longer be able to go back and edit the outline.",
                    cancelLabel: "Go back",
                    proceedLabel: "Yes, start generation",
                  }).then((proceed) => {
                    if (proceed) {
                      generateInstantDraft();
                    }
                  });
                }}
                disabled={isGenerateDisabled}
                variant="primaryBlack"
                size="md"
                className="relative"
              >
                <Icon
                  name="Generate"
                  className="mr-2 stroke-[1.5]"
                  css={
                    instantDraftConfig?.status === InstantDraftStatus.Pending || isLoading
                      ? {
                          animation: "rotateAnimation 0.5s infinite linear",
                        }
                      : undefined
                  }
                />{" "}
                Generate
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default InstantDraftConfigForm;
