/** @jsxImportSource @emotion/react */
import Drawer from "components/organisms/drawer/Drawer";
import Tabs from "components/organisms/tabs/Tabs";
import { ComponentProps, memo, useEffect, useMemo, useState } from "react";
import { useTabFilter, useSearchRequirements } from "../hooks";
import { getTabResultCount } from "../utils";
import { TabSlug } from "../types";
import IconButton from "components/atoms/icon-button/IconButton";
import "twin.macro";
import { Framework, Storage as ImmutableStorage } from "../../CopilotSchemaImmutableTypes";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw, { theme } from "twin.macro";
import { Virtuoso } from "react-virtuoso";
import { EllipsisVertical, Plus, Search } from "lucide-react";
import { Storage } from "../../CopilotSchemaTypes";
import { createComplianceMatrixRow, createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { REQUIREMENT_TABS_META } from "../constants";
import { toggleRequirements } from "store/reducers/copilot/copilotDrawerReducer";
import { setRequirementsDrawerState } from "store/reducers/copilot/requirementsDrawerReducer";
import { useParams } from "react-router-dom";
import { Empty } from "components/molecules/empty";
import RequirementRow from "./RequirementsRow";
import useRequirementOperations from "hook/useRequirementOperations";
import RequirementOptionsDropdown from "components/molecules/requirement-options-dropdown";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { EMPTY_SHEET } from "const-values/Sheets";
import SheetsDropdown from "components/molecules/sheets-dropdown";
import Icon from "components/atoms/icons/Icon";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { useGenerateRequirementHeading } from "hook/draft/useGenerateRequirementHeading";
import { useMutation, useStorage } from "YJSProvider/createYJSContext";
import { isEqual } from "lodash";
import { find, findIndex } from "YJSProvider/LiveObjects";
import useCreateAtlasRequirement from "hook/Requirements/useCreateRequirement";
import { Spinner } from "utils/icons";

interface Props extends Omit<ComponentProps<typeof Drawer>, "opened"> {}

const RequirementsDrawer = ({ onClose, ...props }: Props) => {
  const dispatch = useAppDispatch();
  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const { requirementsOpen } = useAppSelector((root) => root.copilotDrawer);

  useEffect(() => {
    if (requirementsOpen) setTimeout(() => setIsDragEnabled(true), 150);
    if (!requirementsOpen) setIsDragEnabled(false);
  }, [requirementsOpen]);

  return (
    <Drawer
      header={
        <div className="flex flex-col gap-1 pt-1">
          <div className="text-base font-semibold text-stone-900">Requirements</div>
          <div className="text-sm font-normal text-gray-text">Drag and drop requirements into sections</div>
        </div>
      }
      headerStyles={tw`pt-3 pb-1 pr-3`}
      styles={isDragEnabled ? [{ transform: "unset !important" }] : undefined}
      onClose={() => {
        dispatch(toggleRequirements(false));
        onClose?.();
      }}
      opened={requirementsOpen}
      {...props}
    >
      <RequirementsDrawerContent />
    </Drawer>
  );
};

export const RequirementsDrawerContent = memo(() => {
  const {
    activeTab,
    activeSheet,
    config: { animateReqs },
  } = useAppSelector((root) => root.requirementsDrawer);
  const [searchTerm, setSearchTerm] = useState("");
  const { createRequirement: createAtlasRequirement, isCreatingRequirement } = useCreateAtlasRequirement();
  const [requirementEditable, setRequirementEditable] = useState<string | null>(null);
  const { sectionId, volumeId } = useParams();
  const { getFrameworkVolumeSectionTitle, getFrameworkVolumeTitle } = useFrameworkOperations();
  const activeVolume = useStorage(
    (storage) => (storage.framework as Framework)?.volumes?.find((vol) => vol.id === volumeId),
    isEqual,
  );
  const selectedSection = useMemo(
    () => activeVolume?.sections?.find((sec) => sec.id === sectionId),
    [activeVolume?.sections, sectionId],
  );

  const sortedSections = useMemo(() => {
    return (
      activeVolume?.sections?.reduce<FormattedSection[]>((acc, section) => {
        if (!section.parent_id) {
          const subsections = activeVolume?.sections?.filter(({ parent_id }) => parent_id === section.id);
          return [...acc, { ...section, subsections }];
        }

        return acc;
      }, []) || []
    );
  }, [activeVolume?.sections]);
  const shouldShowDefault = useStorage((storage) => {
    const defaultSheetExists = (storage.sheets || []).some((sheet) => sheet.name === EMPTY_SHEET.name);
    return !defaultSheetExists && storage.compliance_matrix.some((row) => !row.requirement?.extraction_id);
  });
  const sheets = useStorage((storage) => [
    ...(shouldShowDefault ? [EMPTY_SHEET] : []),
    ...((storage.sheets as ImmutableStorage["sheets"]) || []),
  ]);
  const activeSheetExists = useMemo(
    () => !!sheets.find((sheet) => sheet.id === activeSheet?.id),
    [activeSheet?.id, sheets],
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!activeSheetExists) dispatch(setRequirementsDrawerState({ activeSheet: sheets[0] || null }));
  }, [activeSheetExists, dispatch, sheets]);

  const complianceMatrixStateBySheet = useStorage(
    (storage) =>
      (storage.compliance_matrix as ImmutableStorage["compliance_matrix"]).filter(
        (row) => !row.requirement?.skipped && activeSheet?.id === (row.requirement.extraction_id || EMPTY_SHEET.id),
      ),
    isEqual,
  );
  const { searchResults } = useSearchRequirements(searchTerm, complianceMatrixStateBySheet);
  const { results } = useTabFilter(activeTab, searchResults);
  const { assignToSection } = useRequirementOperations();
  const { generateRequirementHeading } = useGenerateRequirementHeading();
  const isDisregardedTab = activeTab === TabSlug.Disregarded;
  const isAssignedTab = activeTab === TabSlug.Assigned;

  const addRequirementRow = useMutation(
    async ({ storage }) => {
      try {
        const atlasRequirement = await createAtlasRequirement({ content: "" });

        const createdRequirement = createComplianceMatrixRowRequirement({
          id: atlasRequirement?.id,
          ...(activeSheet?.id !== EMPTY_SHEET.id && { extraction_id: activeSheet?.id }),
        });
        const newRequirement = createComplianceMatrixRow({ requirement: createdRequirement });
        const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];

        if (!!results?.length && matrix) {
          const firstReqIndex = findIndex(
            matrix,
            (row) => row.get("requirement")?.get("id") === results[0]?.requirement?.id,
          );
          if (firstReqIndex === -1) return;
          matrix.insert(firstReqIndex, [newRequirement]);
          setTimeout(() => setRequirementEditable(newRequirement?.get("requirement")?.get("id")), 100);
        } else {
          matrix.insert(0, [newRequirement]);
          setTimeout(() => setRequirementEditable(newRequirement?.get("requirement")?.get("id")), 100);
        }
        newRequirement.get("requirement").set("disregarded", isDisregardedTab);
      } catch {}
    },
    [createComplianceMatrixRow, results, isDisregardedTab, activeSheet, createAtlasRequirement],
  );

  const handleContentChange = useMutation(({ storage }, newTitle, id) => {
    if (!id) return;
    const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    if (!matrix) return;
    const req = find(matrix, (row) => row.get("requirement")?.get("id") === id);
    if (!req) return;
    req.get("requirement")?.set("content", newTitle);
  }, []);

  const requirementTabsWithResultCount = useMemo(
    () =>
      Object.entries(REQUIREMENT_TABS_META).map(([_, tab]) => ({
        ...tab,
        name: `${tab.name} (${getTabResultCount(tab.slug, searchResults)})`,
      })),
    [searchResults],
  );

  return (
    <div className="flex-1 relative w-full">
      <Tooltip
        content={
          isAssignedTab
            ? "Adding a requirement is unavailable in the Assigned tab"
            : searchTerm
              ? "Adding requirements is unavailable \n while searching"
              : "Add requirement"
        }
        contentProps={{ className: "!max-w-[240px]" }}
      >
        <span tabIndex={0} className="z-[1] absolute bottom-4 right-4">
          <button
            onClick={() => addRequirementRow()}
            className="w-[40px] h-[40px] flex items-center justify-center   bg-action text-white rounded-full disabled:shadow-[2px_2px_4px_rgba(0,0,0,0.2)] shadow-[2px_2px_4px_rgba(0,0,0,0.5)] duration-150 hover:bg-action-hover disabled:pointer-events-none disabled:bg-gray-200 disabled:text-slate-400"
            disabled={!!searchTerm.trim() || isAssignedTab || isCreatingRequirement}
          >
            {isCreatingRequirement ? <Spinner /> : <Plus size={26} />}
          </button>
        </span>
      </Tooltip>
      <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col">
        <div className="gap-3 flex flex-row mx-4">
          <div className="flex flex-1 items-center gap-1.5 pl-1.5 text-sm bg-gray-50 rounded-md border border-gray-light">
            <span className="flex items-center text-gray-mid">
              <Search size={18} />
            </span>
            <input
              className="w-full text-sm py-2 bg-transparent focus:outline-none"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              type="text"
              placeholder="Search requirements..."
            />
          </div>
          {activeSheet && (
            <SheetsDropdown
              onSelect={(sheet) => dispatch(setRequirementsDrawerState({ activeSheet: sheet }))}
              selectedSheet={activeSheet?.id || ""}
            >
              <div className="w-[140px] rounded-md justify-between flex items-center gap-4 border border-gray-light px-2 py-2 text-sm text-gray-darkest duration-100 hover:border-gray-600">
                <span className="truncate">{activeSheet?.name}</span>
                <Icon name="CarrotDown" className="min-w-fit" />
              </div>
            </SheetsDropdown>
          )}
        </div>
        <Tabs<TabSlug>
          className="justify-evenly !gap-0"
          tabs={requirementTabsWithResultCount}
          activeTab={
            requirementTabsWithResultCount.find((tab) => tab.slug === activeTab) || requirementTabsWithResultCount[0]
          }
          onTabSelect={(tab) => dispatch(setRequirementsDrawerState({ activeTab: tab.slug }))}
        />
        <div className="flex-1">
          <Virtuoso
            components={{
              EmptyPlaceholder: (props) => (
                <div className="flex items-center justify-center h-full text-center" {...props}>
                  {searchTerm ? (
                    <Empty
                      containerProps={{
                        className: "w-[70%] flex flex-col items-center h-full justify-center m-auto",
                      }}
                      heading="No matching requirements"
                      title="Please try a different search"
                    />
                  ) : (
                    <Empty
                      containerProps={{
                        className: "w-[70%] flex flex-col items-center h-full justify-center m-auto",
                      }}
                      heading="No requirements"
                      title="Extract requirements to get started"
                    />
                  )}
                </div>
              ),
              Item: HeightPreservingItem,
              Header: (props) => <div {...props} className="h-2" css={[!results?.length && tw`hidden`]} />,
              Footer: (props) => <div {...props} className="h-12" css={[!results?.length && tw`hidden`]} />,
            }}
            data={results}
            itemContent={(i, data) => {
              const { requirement, proposal_reference, written_content } = data;
              const volumeTitle = getFrameworkVolumeTitle(proposal_reference?.volume_id || "");
              const sectionTitle = getFrameworkVolumeSectionTitle(
                proposal_reference?.volume_id || "",
                proposal_reference?.section_id || "",
              );
              return (
                <RequirementRow
                  clamp
                  key={requirement.id}
                  reqId={requirement.id}
                  editable={
                    requirementEditable === data.requirement.id ||
                    (!requirement.content?.trim() && !requirement.summarized_content?.trim())
                  }
                  isEditActive={requirementEditable === data.requirement.id}
                  onEdit={(newTitle) => handleContentChange(newTitle, data.requirement.id)}
                  onCloseEdit={() => setRequirementEditable(null)}
                  onOpenEdit={() => setRequirementEditable(data.requirement.id)}
                  actionsButtons={
                    selectedSection &&
                    !isAssignedTab && (
                      <Tooltip
                        content={
                          <div className="flex flex-col gap-1 text-center">
                            <span className="text-xs font-semibold text-gray-800">
                              Click <span className="text-xs font-normal text-gray-800">to add to</span>
                            </span>
                            <span className="text-xs font-normal text-gray-800">section</span>
                          </div>
                        }
                      >
                        <IconButton
                          id={`requirement-drawer-row-${requirement.id}`}
                          onClick={() => {
                            assignToSection(requirement.id, selectedSection.id);
                            if (written_content || requirement?.content || requirement?.summarized_content) {
                              generateRequirementHeading({
                                requirement_ids: [requirement.id],
                              });
                            }
                            dispatch(setRequirementsState({ autoScroll: true }));
                          }}
                          name="Plus"
                          className={animateReqs ? "pulse-animation" : ""}
                          tw="text-slate-100 w-5 h-5 p-0.5 [animation-iteration-count: 5] rounded-full bg-slate-600 duration-150 hover:bg-slate-800 hover:text-white"
                          style={{ "--pulse-bg": theme`colors.slate.600` }}
                        />
                      </Tooltip>
                    )
                  }
                  rowActions={
                    <div className="absolute flex items-center gap-1 bottom-2 right-1">
                      <RequirementOptionsDropdown
                        enableDelete
                        complianceMatrixRow={data}
                        sections={sortedSections || []}
                      >
                        <div className="p-1 flex text-slate-600 z-[1] bg-transparent rounded hover:text-slate-900 hover:bg-slate-200">
                          <EllipsisVertical size={14} />
                        </div>
                      </RequirementOptionsDropdown>
                    </div>
                  }
                  title={requirement.content || requirement.summarized_content || ""}
                  footer={
                    activeTab === TabSlug.Assigned && volumeTitle ? (
                      <div className="flex flex-col pl-2.5 pr-10 py-2 gap-0.5">
                        <div className="text-xs font-normal text-gray-500">Assigned to:</div>
                        <div className="">
                          <span className="text-xs font-semibold text-stone-900">Volume: </span>
                          <span className="text-xs font-normal text-stone-900">{volumeTitle}</span>
                        </div>
                        {sectionTitle && (
                          <div className="">
                            <span className="text-xs font-semibold text-stone-900">Section: </span>
                            <span className="text-xs font-normal text-stone-900">{sectionTitle}</span>
                          </div>
                        )}
                      </div>
                    ) : undefined
                  }
                  searchTerm={searchTerm}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
});

RequirementsDrawerContent.displayName = "RequirementsDrawerContent";

export default RequirementsDrawer;

const HeightPreservingItem = ({ children, item, ...props }: any) => {
  const [size, setSize] = useState(0);
  const knownSize = props["data-known-size"];
  useEffect(() => {
    setSize((prevSize) => {
      return knownSize === 0 ? prevSize : knownSize;
    });
  }, [knownSize]);

  return (
    <div
      {...props}
      className="height-preserving-container"
      style={{
        "--child-height": `${size}px`,
      }}
    >
      {children}
    </div>
  );
};
