export const INTERNAL_DOCUMENT_CLASSIFICATIONS: { [key: string]: string } = {
  solicitation: "Solicitation",
  intelligence: "Intelligence",
  meeting_notes: "Meeting Notes",
  customer_insight: "Customer Insight",
  no_classification: "No Classification",
};

export const NO_CLASSIFICATION = "no_classification";

export const PROJECT_SPECIFIC_BASE_PATH = "/dashboard/contracts/details/documents/project-specific";
