import React from "react";
import { X, Trash2 } from "lucide-react";
import Icon from "components/atoms/icons/Icon";
import { pluralizeWord } from "utils/string";
import { ToolbarButton } from "./components/ToolbarButton";

interface SelectedFilesToolbarProps {
  selectedCount: number;
  onClearSelection: () => void;
  onMove: () => void;
  onDelete: () => void;
  documentType?: string;
}

export const SelectedFilesToolbar: React.FC<SelectedFilesToolbarProps> = ({
  selectedCount,
  onClearSelection,
  onMove,
  onDelete,
  documentType = "Document",
}) => {
  return (
    <div className="fixed bottom-[24px] z-[990] flex justify-center duration-200 left-0 right-0 pointer-events-none">
      <div className="flex items-center gap-3 flex-row rounded-md p-2.5 bg-gray-darkest pointer-events-auto cursor-default">
        <div className="flex h-7 items-center">
          <div className="text-gray-100 h-[inherit] flex items-center px-2 text-xs border border-r-0 border-dashed border-gray-500 rounded-l">
            {selectedCount} {pluralizeWord(selectedCount, documentType)}
          </div>
          <button
            onClick={onClearSelection}
            className="h-[inherit] text-gray-200 text-base px-1.5 border border-dashed border-gray-500 rounded-r duration-100 hover:text-white hover:border-gray-100"
          >
            <X size={16} />
          </button>
        </div>
        <div className="bg-gray-500 w-px h-6" />
        <div className="flex items-center gap-2">
          <ToolbarButton onClick={onMove}>
            <Icon name="Swap" className="text-sm" />
            <span className="text-xs">Move</span>
          </ToolbarButton>
          <ToolbarButton onClick={onDelete}>
            <Trash2 size={14} />
            <span className="text-xs">Delete</span>
          </ToolbarButton>
        </div>
      </div>
    </div>
  );
};
