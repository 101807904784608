import { useCallback, useState } from "react";
import add from "../../Assets/add-blue-circle.svg";
import link from "../../Assets/link.svg";
import dots from "../../Assets/3dots.svg";
import LinkModal from "./modals/LinkModal";
import { deleteInternalContractLink } from "api/api";
import { Spinner } from "utils/icons";
import { useNotification } from "context/notificationContext";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Trash2 } from "lucide-react";
import type { UrlLink } from "types/Project";

type Props = {
  links: UrlLink[];
  internalContractId: string;
  setForceRefresh: (force: boolean) => unknown;
  searchPage: boolean;
};
const LinksSection = ({ links, internalContractId, setForceRefresh, searchPage }: Props) => {
  const { setToast } = useNotification();
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [deletingLink, setDeletingLink] = useState<string[]>([]);

  const onDeleteBtn = useCallback(
    (linkId: string) => {
      deleteInternalContractLink(linkId)
        .then(() => {
          const type = searchPage ? "Link" : "Bookmark";
          setToast.success({
            msg: `${type} deleted`,
          });

          setForceRefresh(true);
        })
        .catch(() => {
          setToast.error({
            title: "Unable to delete bookmark",
            msg: "We were unable to delete the bookmark due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
        });
    },
    [searchPage, setForceRefresh, setToast],
  );

  const sourceItems = useCallback(
    (nLink: UrlLink) => {
      return [
        ...(!searchPage
          ? [
              {
                key: 1,
                label: (
                  <div className="flex items-center gap-2 text-red-500">
                    {deletingLink?.includes(nLink?.id || "") ? (
                      <Spinner width={13} height={13} />
                    ) : (
                      <Trash2 size={14} />
                    )}
                    Delete
                  </div>
                ),
                disabled: !!deletingLink?.includes(nLink?.id || ""),
                onSelect: () => {
                  if (nLink?.id) {
                    setDeletingLink([...deletingLink, nLink.id]);
                    onDeleteBtn(nLink.id);
                  }
                },
              },
            ]
          : []),
      ];
    },
    [deletingLink, onDeleteBtn, searchPage],
  );

  return (
    <>
      <LinkModal
        showLinkModal={showLinkModal}
        setShowLinkModal={setShowLinkModal}
        internalContractId={internalContractId}
        setForceRefresh={setForceRefresh}
        searchPage={searchPage}
      />
      <div className="border border-[#D8DDE2] shadow-sm rounded-lg p-4 bg-white mb-4">
        <div className="flex justify-between items-center mb-5">
          <h3 className="text-base font-medium">{searchPage ? "External Links" : "Bookmarks"}</h3>
          {!searchPage && (
            <button onClick={() => setShowLinkModal(true)}>
              <img src={add} alt="" className="cursor-pointer" />
            </button>
          )}
        </div>
        {links.length > 0 ? (
          links.map((nLink, i) => (
            <div
              className="flex items-center max-w-full border border-gray-300 my-2 rounded-lg shadow-sm justify-between cursor-pointer"
              key={i}
            >
              <div
                className="flex items-center max-w-[90%] rounded-l-lg gap-2 p-3 flex-grow"
                onClick={() => window.open(nLink?.url, "_blank", "noopener,noreferrer")}
              >
                <img src={link} alt="" />
                <p className="text-sm text-gray-700 break-words max-w-[90%]">{nLink?.name}</p>
              </div>
              {!searchPage && (
                <DropdownMenu modal items={sourceItems(nLink)}>
                  <div className="pl-1 pr-2 ml-auto cursor-pointer">
                    <img src={dots} alt="" className="select-none" />
                  </div>
                </DropdownMenu>
              )}
            </div>
          ))
        ) : (
          <p
            className={`text-[#5B6B79] px-2 text-sm transition-all duration-300 ease-linear outline-0 text-center mb-4`}
          >
            No {searchPage ? "links" : "bookmarks"} available
          </p>
        )}
      </div>
    </>
  );
};

export default LinksSection;
