export const fileProcessed = (
  indexed: boolean,
  createdAt: string | undefined,
  indexTimeInMinutes: number,
  useIndexed?: boolean,
): boolean => {
  if (useIndexed) return indexed;
  if (!createdAt) return false;

  const createdAtTime = new Date(createdAt).getTime();
  if (isNaN(createdAtTime)) return false;

  const timeDifferenceInMinutes = (new Date().getTime() - createdAtTime) / 60000;
  return timeDifferenceInMinutes > indexTimeInMinutes;
};
