/** @jsxImportSource @emotion/react */
import dots from "Assets/3dots.svg";
import dltLoader from "Assets/delete-loader.svg";
import { setDraggingDocIds, setDraggingFolderId } from "store/reducers/driveReducerSlice";
import { useAppDispatch } from "store/storeTypes";
import { WorkspaceMedia } from "types/FileStorage";
import { MoveIcon } from "utils/icons";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import tw from "twin.macro";
import { Download, ExternalLink, Trash2 } from "lucide-react";
import { Checkbox } from "components/atoms/checkbox";
import { useMediaOperations } from "./useMediaOperations";
import { useMemo } from "react";
import { createDragHandler } from "../utils/dragPreview";

interface Props {
  media: WorkspaceMedia;
  setSelectedMedia: (media: WorkspaceMedia) => void;
  setIsPreviweModalOpen: (isOpen: boolean) => void;
  setIsMoveMediaFileModalOpen: (isOpen: boolean) => void;
  onSelect: (id: string, isSelected: boolean) => void;
  isSelected: boolean;
  selectedMediaIds: string[];
  onMediaClick: (media: WorkspaceMedia) => void;
  isMediaPanelOpen: boolean;
  setPreviewMedia: (media: WorkspaceMedia) => void;
  setMoveMediaItem: (media: WorkspaceMedia) => void;
}

export default function Media({
  media,
  setSelectedMedia,
  setIsPreviweModalOpen,
  setIsMoveMediaFileModalOpen,
  onSelect,
  isSelected,
  selectedMediaIds,
  onMediaClick,
  isMediaPanelOpen,
  setPreviewMedia,
  setMoveMediaItem,
}: Props) {
  const { id, file_name, formatted_updated_date, download_url, formatted_set_date } = media;
  const { deleteFiles, isLoading } = useMediaOperations();
  const dispatch = useAppDispatch();

  const handleDragStart = createDragHandler({
    id: media.id,
    selectedIds: selectedMediaIds,
    displayName: file_name,
    itemsName: "graphics",
    onDragStart: (draggedIds) => {
      dispatch(setDraggingDocIds(draggedIds));
      dispatch(setDraggingFolderId(""));
    },
  });

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2">
            <Download size={14} /> Download
          </div>
        ),
        onSelect: () => {
          const a = document.createElement("a");
          a.href = download_url;
          a.download = file_name;
          a.click();
        },
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2">
            <MoveIcon />
            Move
          </div>
        ),
        onSelect: () => {
          setIsMoveMediaFileModalOpen(true);
          setMoveMediaItem(media);
        },
      },
      {
        key: 3,
        label: (
          <div className="flex items-center gap-2 text-red-500">
            {isLoading ? <img src={dltLoader} alt="icon" width={14} height={14} /> : <Trash2 size={14} />}
            Delete
          </div>
        ),
        disabled: isLoading,
        onSelect: async () => {
          await deleteFiles([id]);
        },
      },
    ],
    [deleteFiles, download_url, file_name, id, isLoading, media, setIsMoveMediaFileModalOpen, setSelectedMedia],
  );

  return (
    <div
      data-drag-ignore
      className="card group relative flex flex-col w-full rounded-lg cursor-pointer overflow-hidden"
      css={[isSelected || isMediaPanelOpen ? tw`border-2 border-gray-darkest` : tw`border-2 border-gray-light`]}
      onDragStart={handleDragStart}
      draggable
      data-doc-id={id}
      onClick={() => onMediaClick(media)}
    >
      <div className="absolute top-3 left-3 z-10" onClick={(e) => e.stopPropagation()}>
        <Checkbox checked={isSelected} onCheck={(checked) => onSelect(id, checked)} variant="primaryBlack" />
      </div>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setPreviewMedia(media);
          setIsPreviweModalOpen(true);
        }}
        className="text-xs z-[1] select-none flex items-center gap-2 ml-auto absolute p-1.5 top-2 right-2 shadow-sharp-thin rounded transition-opacity opacity-0 bg-gray-300 text-slate-900 group-hover:opacity-100 hover:brightness-95 font-medium"
      >
        <span className="truncate">Open</span>
        <ExternalLink size={14} className="shrink-0" />
      </button>

      <figure className="relative overflow-hidden w-full h-48 bg-[#DEE1E3] flex justify-center items-center cursor-pointer">
        <img
          src={download_url}
          alt="graphics"
          loading="lazy"
          className="w-full max-w-full h-full pointer-events-none object-cover"
        />
        <div className="iframe-overlay absolute h-full w-full bg-[transparent]"></div>
      </figure>

      <div className="border-t flex-1 bg-white p-4 z-20">
        <div className="flex justify-between gap-2">
          <p className="font-bold text-[10px] break-all mb-2">{file_name}</p>
          <div onClick={(e) => e.stopPropagation()}>
            <DropdownMenu triggerProps={{ css: tw`h-fit` }} items={menuItems}>
              <div className="cursor-pointer ml-auto min-w-[20px]">
                <img src={dots} alt="Three Dots" className="max-w-full mx-auto pointer-events-none object-contain" />
              </div>
            </DropdownMenu>
          </div>
        </div>
        <div>
          <p className="text-[10px] text-[#5B6B79] mb-2">{formatted_set_date || formatted_updated_date}</p>
        </div>
      </div>
    </div>
  );
}
