import { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

////////////////////// IMPORTING API FUNCTIONS //////////////////////////
import { getSingleDirectory } from "api/api";
import CustomModal from "components/CustomModal";
import Document from "components/Drive/Document";
import Folder from "components/Drive/Folder";
import UploadDriveDocModal from "components/Drive/UploadDriveDocModal";
import ExtractingDocumentToast from "components/LoadingToast";
import { useNotification } from "context/notificationContext";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { fetchFileStorage, updateAllDocs, updateAllFolders } from "store/reducers/driveReducerSlice";
import { useAppSelector } from "store/storeTypes";
import { CloseIconBlack } from "utils/icons";
import { Plus } from "lucide-react";
import lod from "../../Assets/loader.svg";
import searchIcon from "../../Assets/search-normal.png";
import MoveModal from "./MoveModal";
import { useDirectoryFileSearch } from "./documents/hooks";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import Icon from "components/atoms/icons/Icon";
import { xor } from "lodash";
import NotFoundItems from "components/NotFoundItems";
import { useContentLibraryFilterItems } from "components/molecules/content-drive-popover-content/hooks";
import { LEGACY_DOCUMENT_TYPES } from "pages/drive/documents/constants";
import { useFileOperations } from "./documents/useFileOperations";
import { SelectedFilesToolbar } from "./toolbar/SelectedFilesToolbar";
import { ContentLibraryPanel } from "./sidebar/ContentLibraryPanel";
import { useFolderOperations } from "./documents/useFolderOperations";
import { useOperationsUtils } from "./documents/useOperationsUtils";
import { useDragSelect } from "components/Drive/useDragSelect";
import { useOutletContext } from "react-router-dom";
import { DragAndDropToast } from "./DragAndDropToast";

const FolderPage = () => {
  const dispatch = useDispatch();
  const { rootFiles: allMainDocs, rootSubdirectories: allMainFolders } = useAppSelector(
    (state) => state.drive.fileStorage,
  );

  const { folderId } = useParams();

  const [allDocs, setAllDocs] = useState([]);
  const [allFolders, setAllFolders] = useState([]);

  const {
    isSearching,
    searchResults,
    isSearchActive,
    setDocumentTypes,
    setSearchQuery,
    searchQuery,
    documentTypes,
    setUploaded,
    setDirectoryId,
    uploaded,
    folderSearchResults,
    throttleSearch,
  } = useDirectoryFileSearch();

  const { handleApiError, handleApiSuccess } = useOperationsUtils();

  const [currentDirectoryName, setCurrentDirectoryName] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isInitialFetch, setIsInitialFetch] = useState(false);
  const [currentFolders, setCurrentFolders] = useState([]);
  const [currentDocs, setCurrentDocs] = useState([]);
  const [loadingToast, setLoadingToast] = useState({ loading: false, files: 1 });
  const [draggingFolderId, setDraggingFolderId] = useState("");
  const [isDragOverDrive, setIsDragOverDrive] = useState(false);
  const [draggingDocIds, setDraggingDocIds] = useState([]);
  const [selectedFolderHeading, setSelectedFolderHeading] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [stateHistory, setStateHistory] = useState({
    type: null,
    data: [],
    itemInfo: {},
  });
  const { scrollContainerRef } = useOutletContext();
  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);
  const { typeItems, publishDateItemOptions } = useContentLibraryFilterItems({
    publishDatesConfig: { uploaded, onSelect: (val) => setUploaded(val === uploaded ? "" : val) },
    typesConfig: {
      documentTypes,
      onCheck: (val) => {
        if (Array.isArray(val)) {
          setDocumentTypes((prev) => xor(prev, val));
        } else {
          setDocumentTypes((prev) => xor(prev, [val]));
        }
      },
    },
  });
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const fetchData = useCallback(
    async (omitLoader) => {
      if (!omitLoader) setIsInitialFetch(true);
      try {
        const { data } = await getSingleDirectory(folderId);

        setCurrentDirectoryName(data.current_directory.name);
        setAllFolders(data.subdirectories);
        setCurrentFolders(data.subdirectories);
        setAllDocs(data.files);
        setCurrentDocs(data.files);
      } catch (err) {
        console.error(err);
        handleApiError(
          err,
          "We were unable to retrieve data due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        );
      } finally {
        if (!omitLoader) setIsInitialFetch(false);
      }
    },
    [folderId, handleApiError],
  );

  const refreshResults = useCallback(() => {
    if (isSearchActive) {
      throttleSearch(true);
    } else {
      fetchData(true);
      dispatch(fetchFileStorage());
    }
  }, [fetchData, isSearchActive, throttleSearch]);

  const {
    folderModal,
    createFolder,
    updateFolder,
    deleteFolder: deleteSelectedDirectory,
    moveFolder: moveSelectedDirectory,
    openFolderModal,
    closeFolderModal,
    updateFolderName,
  } = useFolderOperations(allFolders, refreshResults, setStateHistory, {
    parentFolderId: folderId,
    setAllFolders: setAllFolders,
  });

  const {
    createDocument,
    isValidFile,
    updateDocument,
    moveDocument,
    deleteDocument,
    moveModalOpen,
    handleMoveModalOpen,
    handleCloseMoveModal,
    onMoveManually,
  } = useFileOperations(allDocs, allFolders, refreshResults, setStateHistory, setSelectedDocuments, setAllDocs);

  const handleDocumentSelect = useCallback((id, isSelected) => {
    setSelectedDocuments((prev) => (isSelected ? [...prev, id] : prev.filter((docId) => docId !== id)));
  }, []);

  const handleMoveSelected = useCallback(() => {
    handleMoveModalOpen(selectedDocuments, "doc");
  }, [selectedDocuments, handleMoveModalOpen]);

  const handleDeleteSelected = () => {
    if (selectedDocuments.length > 0) {
      deleteDocument(selectedDocuments);
    }
  };

  const handleDocumentClick = useCallback((doc) => {
    setSelectedDocument(doc);
    setIsPanelOpen(true);
  }, []);

  const handleClosePanel = useCallback(() => {
    setSelectedDocument(null);
    setIsPanelOpen(false);
  }, []);

  const handleCreateDocument = useCallback(
    async (files, targetId = "", tags = []) => {
      if (!files.length) {
        setLoadingToast({
          loading: false,
          files: 1,
        });
        return;
      }

      setLoadingToast({
        loading: true,
        files: files?.length,
      });

      try {
        await createDocument(files, {
          parentDirectoryId: targetId || folderId,
          tags,
        });
        setShowUploadModal(false);
      } finally {
        setLoadingToast({
          loading: false,
          files: 1,
        });
      }
    },
    [createDocument, folderId],
  );

  useDragSelect({
    onSelect: handleDocumentSelect,
    selectedDocuments,
    scrollContainerRef,
  });

  useEffect(() => {
    // Check if allDocs and allFolders are empty in redux before dispatching the action
    if (!allMainDocs.length && !allMainFolders.length) {
      dispatch(fetchFileStorage({ isInitialFetch: true }));
    }
  }, [dispatch, allMainDocs.length, allMainFolders.length]);

  //////////// fetch data on initial render
  useEffect(() => {
    fetchData();
    setDirectoryId(folderId);
  }, [folderId]);

  useEffect(() => {
    if (isSearchActive) {
      setCurrentDocs(searchResults);
      setCurrentFolders(folderSearchResults);
    } else {
      setCurrentDocs(allDocs);
      setCurrentFolders(allFolders);
    }
  }, [allDocs, allFolders, folderSearchResults, isSearchActive, searchResults]);

  ///////////////////////////DRAG AND DROP COMPUTER FUNCTIONALITY ////////////////////////////

  ///// function to handle files droppped from computer into drive
  function onMainDrop(acceptedFiles) {
    if (showUploadModal) return;
    setSelectedFolderHeading("");
    setIsDragOverDrive(false);
    const validFiles = acceptedFiles.filter((file) => isValidFile(file));
    if (validFiles.length) {
      handleCreateDocument(validFiles);
    }
  }

  ///// function to handle files droppped from computer into any folder
  function onFolderDrop(acceptedFiles, targetFolderId) {
    setSelectedFolderHeading("");
    setIsDragOverDrive(false);
    const validFiles = acceptedFiles.filter((file) => isValidFile(file));
    if (validFiles.length) {
      handleCreateDocument(validFiles, targetFolderId);
    }
  }

  const { getRootProps: getMainRootProps, getInputProps: getMainInputProps } = useDropzone({
    onDrop: onMainDrop,
    onDragOver: (e) => {
      e.preventDefault();
      if (showUploadModal) return;
      setIsDragOverDrive(true);
      setDraggingFolderId("");
    },
    onDragLeave: (e) => {
      const relatedTarget = e.relatedTarget;
      if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget))) {
        setIsDragOverDrive(false);
      }
    },
    noClick: true,
  });

  // ////////////// setting up ctrl + z //////////
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Control") {
        setCtrlKeyPressed(true);
      }
      if (event.key === "z" && ctrlKeyPressed) {
        undo();
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Control") {
        setCtrlKeyPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [ctrlKeyPressed]);

  // /////// perform undo method for docs & folders ///////////////////
  const undo = async () => {
    if (!stateHistory?.type) return;
    if (stateHistory?.type === "folder") {
      await moveSelectedDirectory(stateHistory?.itemInfo?.parent_directory, stateHistory?.itemInfo?.id, "prevent");
      dispatch(updateAllFolders(allMainFolders?.filter((fl) => fl?.id !== stateHistory?.itemInfo?.id) || []));
      setAllFolders(stateHistory?.data);
      setStateHistory({ type: null, data: [], itemInfo: {} });
    } else {
      await moveDocument(stateHistory?.itemInfo?.parent_directory, stateHistory?.itemInfo?.id, "prevent");
      dispatch(updateAllDocs(allMainDocs?.filter((dc) => dc?.id !== stateHistory?.itemInfo?.id)));
      setAllDocs(stateHistory?.data);
      setStateHistory({ type: null, data: [], itemInfo: {} });
    }
  };

  return (
    <main className={`relative flex-grow ${isPanelOpen ? "mr-[400px]" : ""} `} {...getMainRootProps()}>
      <input multiple {...getMainInputProps()} />

      <div className="relative flex-grow">
        {/* --------- DOTTED LINE APPEARS ON DRAG AND DROP ----------- */}
        <div
          className={`${
            (isDragOverDrive || selectedFolderHeading) &&
            !showUploadModal &&
            ((!draggingFolderId && !draggingDocIds.length) || // Computer file drag
              (draggingFolderId &&
                currentFolders?.find((f) => f.id === draggingFolderId)?.id !== selectedFolderHeading) || // Folder drag
              draggingDocIds.length) // Document drag
              ? "border-[3px] border-dashed border-[#1E1E1E]"
              : ""
          } absolute w-full h-full pointer-events-none z-50`}
        />

        <div className="relative">
          {/* ---------------------------------- SECTION SEARCH -------------------------------- */}
          <section className="pt-2 pb-6 px-1">
            <div className="flex gap-3">
              <div className="flex-1 relative">
                <img src={searchIcon} alt="search icon" loading="lazy" className="absolute top-2.5 left-1.5 w-5" />

                <input
                  placeholder="Search..."
                  className="bg-white focus:outline-none block focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full h-10 py-3 pl-9 pr-3 rounded-md border border-gray-light"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onClick={handleClosePanel}
                />
              </div>

              <div className="flex items-center gap-3 flex-wrap">
                <DropdownMenu multiselect items={typeItems}>
                  <div className="rounded-md min-w-[140px] text-sm h-10 w-[140px] bg-white flex justify-between px-2 py-1 items-center gap-2 border border-gray-light text-slate-600">
                    <div className="truncate">
                      {documentTypes.length
                        ? documentTypes.filter((document) => !LEGACY_DOCUMENT_TYPES.includes(document)).join(", ")
                        : "Type"}
                    </div>
                    <Icon name="CarrotDown" className="min-w-3" />
                  </div>
                </DropdownMenu>
                <DropdownMenu items={publishDateItemOptions}>
                  <div className="rounded-md min-w-[140px] text-sm h-10 w-[140px] bg-white flex justify-between px-2 py-1 items-center gap-2 border border-gray-light text-slate-600">
                    <div className="truncate">{uploaded || "Uploaded"}</div>
                    <Icon name="CarrotDown" className="min-w-3" />
                  </div>
                </DropdownMenu>
              </div>
            </div>
          </section>
          {/* ---------------------------- FOLDERS SECTION ------------------------------ */}
          {!isSearchActive && (
            <section className="folders-section min-h-[150px] px-1 pt-1">
              <div className="flex justify-between items-center mb-6">
                <h5 className="font-medium text-md">Folders</h5>
                <div
                  className="flex items-center gap-1 rounded-md cursor-pointer px-1.5 py-1 bg-gray-100 hover:border-gray-300 border border-gray-200"
                  onClick={() => openFolderModal()}
                >
                  <span className="text-sm font-semibold">Folder</span>
                  <Plus size={14} />
                </div>
              </div>

              {isInitialFetch && !currentFolders.length ? (
                <div className="w-full flex justify-center flex-col py-4 px-2">
                  <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
                  <div className="flex-grow" />
                </div>
              ) : currentFolders.length ? (
                <div className="cards grid grid-cols-[repeat(auto-fill,_minmax(230px,_1fr))] gap-5 mb-8">
                  {currentFolders.map((folder) => (
                    <Folder
                      key={folder.id}
                      {...folder}
                      draggingFolderId={draggingFolderId}
                      setDraggingFolderId={setDraggingFolderId}
                      draggingDocIds={draggingDocIds}
                      setDraggingDocIds={setDraggingDocIds}
                      moveSelectedDirectory={moveSelectedDirectory}
                      handleDelete={deleteSelectedDirectory}
                      moveDocument={moveDocument}
                      createDocument={createDocument}
                      setSelectedFolderHeading={setSelectedFolderHeading}
                      setIsDragOverDrive={setIsDragOverDrive}
                      selectedFolderHeading={selectedFolderHeading}
                      onFolderDrop={onFolderDrop}
                      handleMoveModalOpen={handleMoveModalOpen}
                      openFolderModal={openFolderModal}
                    />
                  ))}
                </div>
              ) : (
                !isSearchActive && (
                  <div className="pb-10">
                    <h5 className="text-base text-center font-semibold text-[#1D2630]">No Folders</h5>
                    <p className="text-[#353535] text-center pt-[5px]">
                      Create a folder by clicking on the “Folder +” button.
                    </p>
                  </div>
                )
              )}

              <CustomModal isOpen={folderModal.isOpen} onClose={closeFolderModal}>
                <div className="p-5 w-[444px]">
                  <div className="flex justify-between items-center mb-5">
                    <h3 className="font-semibold text-xl">
                      {folderModal.selectedFolderId ? "Update Folder" : "New Folder"}
                    </h3>
                    <div className="w-[15.56px] h-[15.56px]">
                      <span className="cursor-pointer">
                        <CloseIconBlack onClick={closeFolderModal} />
                      </span>
                    </div>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      folderModal.selectedFolderId ? updateFolder() : createFolder();
                    }}
                  >
                    <label className="mb-2 block text-sm text-[#5B6B79]">Name</label>
                    <input
                      type="text"
                      className="w-full rounded-md py-2 px-3 h-10 border-[1px] border-[#CFD1D4] focus:ring-gray-darkest focus:border-gray-darkest outline-none mb-7"
                      value={folderModal.folderName}
                      onChange={(e) => updateFolderName(e.target.value)}
                      autoFocus
                      required
                    />

                    <div className="flex justify-end gap-4">
                      <button
                        type="button"
                        className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                        onClick={closeFolderModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="border-0 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
                      >
                        {folderModal.selectedFolderId ? "Update" : "Add"}
                      </button>
                    </div>
                  </form>
                </div>
              </CustomModal>
            </section>
          )}
          {/* -------------------------- SECTION DOCUMENT -------------------------------- */}
          <section className="document-section px-1 relative mb-9">
            <div className="flex items-center justify-between mb-3">
              <div className="flex flex-col gap-3">
                <h5 className="font-medium text-md mb-4">Documents</h5>
              </div>
              {!isSearchActive && (
                <div
                  onClick={() => setShowUploadModal(true)}
                  className="flex items-center gap-1 rounded-md cursor-pointer px-1.5 py-1  bg-gray-100 hover:border-gray-300 border border-gray-200"
                >
                  <span className="text-sm font-semibold">Document</span>
                  <span className="relative block cursor-pointer">
                    <Plus size={14} />
                  </span>
                </div>
              )}
            </div>
            {isInitialFetch || isSearching ? (
              <div className="w-full flex justify-center flex-col py-14 px-2">
                <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
                <div className="flex-grow" />
              </div>
            ) : (
              <>
                {!!currentDocs.length && (
                  <div className="cards grid grid-cols-[repeat(auto-fill,_minmax(230px,_1fr))] gap-5">
                    {currentDocs.map((doc, i) => (
                      <Document
                        key={i + doc?.id}
                        docData={doc}
                        deleteDocument={deleteDocument}
                        setDraggingFolderId={setDraggingFolderId}
                        setDraggingDocIds={setDraggingDocIds}
                        handleMoveModalOpen={handleMoveModalOpen}
                        onSelect={handleDocumentSelect}
                        isSelected={selectedDocuments.includes(doc.id)}
                        selectedDocuments={selectedDocuments}
                        onDocumentClick={handleDocumentClick}
                        isDocumentPanelOpen={selectedDocument?.id === doc.id}
                      />
                    ))}
                  </div>
                )}
                {/* -------------- CONTENT LIBRARY PANEL ---------------- */}
                {selectedDocument && (
                  <ContentLibraryPanel
                    doc={selectedDocument}
                    isOpen={isPanelOpen}
                    onClose={handleClosePanel}
                    onUpdate={updateDocument}
                  />
                )}
                {isSearchActive && !currentDocs.length && (
                  <NotFoundItems
                    title="No documents found"
                    subTitle="No matching results. Try another search."
                    className={"w-full mt-24 flex justify-center items-center bg-inherit"}
                  />
                )}
                {!isSearchActive && (
                  <div className="mb-5">
                    <UploadDriveDocModal isDrive={!currentDocs.length} createDocument={handleCreateDocument} />
                  </div>
                )}
              </>
            )}

            {/* ------------ UPLOAD files MODAL --------------- */}
            <UploadDriveDocModal
              showUploadModal={showUploadModal}
              setShowUploadModal={setShowUploadModal}
              createDocument={handleCreateDocument}
            />
          </section>
        </div>
      </div>

      {/* ------------- Drag and Drop Toast -------------------- */}
      <DragAndDropToast
        isDragOverDrive={isDragOverDrive}
        selectedFolderHeading={selectedFolderHeading}
        draggingFolderId={draggingFolderId}
        draggingDocIds={draggingDocIds}
        currentFolders={currentFolders}
        hideToast={!!showUploadModal}
      />

      {loadingToast?.loading && (
        <ExtractingDocumentToast
          loadingText={`Uploading ${loadingToast?.files > 1 ? "Documents" : "Document"}`}
          handleClose={() => setLoadingToast({ loading: false, files: 1 })}
        />
      )}

      {/* ------------ Move Modal --------------- */}
      <MoveModal
        selectedItems={moveModalOpen.items}
        handleCloseMoveModal={handleCloseMoveModal}
        onMove={onMoveManually}
        currentFiles={currentDocs}
        isMain={false}
      />

      {!selectedFolderHeading && selectedDocuments.length > 0 && (
        <SelectedFilesToolbar
          selectedCount={selectedDocuments.length}
          onClearSelection={() => setSelectedDocuments([])}
          onMove={handleMoveSelected}
          onDelete={handleDeleteSelected}
        />
      )}
    </main>
  );
};

export default FolderPage;
